import { Divider } from "@material-ui/core";
import {
  Button,
  majorScale,
  Pane,
  Spinner,
  Textarea,
  TextInput,
  toaster,
} from "evergreen-ui";
import { useFormik } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import axiosClient from "../../../network/RestClient";
import HeaderComponent from "../../components/HeaderComponent";
import RowLabelComponent from "../../components/RowLabelComponent";

function CategoryCreatePage() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const create = async () => {
    setSubmitting(true);
    try {
      const res = await axiosClient.post("/cms/category/create", {
        name: formik.values.name || "",
        description: formik.values.description || "",
        image: formik.values.image,
        keyword: [],
        status: "active",
      });
      if (res && res.status === 0) {
        toaster.success("Đã tạo mới thành công");
        history.push("/category/list");
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      image: "",
      keyword: [],
      status: "active",
    },
    onSubmit: (values) => {
      create();
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      description: Yup.string().required(),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(4)}
      marginY={majorScale(2)}
      flexDirection="column"
    >
      {loading ? (
        <Spinner />
      ) : (
        <Pane
          display="flex"
          marginY={majorScale(2)}
          paddingX={majorScale(4)}
          paddingY={majorScale(3)}
          flexDirection="column"
          background="white"
        >
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            marginBottom={majorScale(2)}
          >
            <HeaderComponent>Tạo mới chuyên mục</HeaderComponent>
          </Pane>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <RowLabelComponent
              leading="Tên chuyên mục"
              trailing={
                <TextInput
                  name="name"
                  placeholder="Nhập tên chuyên mục"
                  type="text"
                  width={550}
                  maxWidth={550}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  isInvalid={formik.errors.name != null}
                />
              }
            />
            <RowLabelComponent
              leading="Mô tả"
              trailing={
                <Textarea
                  name="description"
                  placeholder="Nhập mô tả cho chuyên mục"
                  type="text"
                  width={550}
                  maxWidth={550}
                  height={200}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  isInvalid={formik.errors.description != null}
                />
              }
            />

            <Divider light={true} />
            <Button
              type="submit"
              isLoading={submitting}
              appearance="primary"
              width={150}
              marginTop={majorScale(3)}
              marginRight={majorScale(3)}
            >
              Tạo mới
            </Button>
          </form>
        </Pane>
      )}
    </Pane>
  );
}

export default CategoryCreatePage;
