import moment from "moment";

export const formatDate = function (timestamp, format = "HH:mm DD/MM/YYYY") {
  const t = moment(timestamp);
  return t.format(format);
};

export const statusLabel = (status) => {
  switch (status) {
    case "active":
      return "Hoạt động";
    case "block":
      return "Bị khóa";
    default:
      return "N/A";
  }
};

export const statusSelftUpdate = (status) => {
  switch (status) {
    case true:
      return "Hoàn thiện";
    default:
      return "Chưa bổ sung";
  }
};

export const accessLabel = (status) => {
  switch (status) {
    case "member":
      return "Member thường";
    case "premium":
      return "Hạng Gold";
    case "platium":
      return "Hạng Platium";
    default:
      return "Miễn phí";
  }
};

export const memberTypeLabel = (status) => {
  switch (status) {
    case "member":
      return "Member thường";
    case "premium":
      return "Hạng Gold";
    case "platium":
      return "Hạng Platium";
    default:
      return "Member";
  }
};

export const actionLabel = (status) => {
  switch (status) {
    case "buy":
      return "Mua vào";
    case "hold":
      return "Nắm giữ";
    default:
      return "Đã đóng";
  }
};

export const actionColor = (status) => {
  switch (status) {
    case "buy":
      return "red";
    case "hold":
      return "blue";
    default:
      return "grey";
  }
};
