import { Route, Switch } from "react-router-dom";
import NewsPage from "./list";
import NewsCreatePage from "./create";
import NewsDetailPage from "./detail";


function NewsRouter() {
  return (
    <Switch>
      <Route key="/news/list" path="/news/list" component={NewsPage} />
      <Route
        key="/news/detail/:id"
        path="/news/detail/:id"
        component={NewsDetailPage}
      />
      <Route
        key="/news/create"
        path="/news/create"
        component={NewsCreatePage}
      />
    </Switch>
  );
}

export default NewsRouter;
