import { Divider } from "@material-ui/core";
import {
  Button,
  Checkbox,
  CornerDialog,
  Heading,
  majorScale,
  Pane,
  Select,
  Spinner,
  Textarea,
  TextInput,
  toaster,
  TrashIcon,
} from "evergreen-ui";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import axiosClient from "../../../network/RestClient";
import HeaderComponent from "../../components/HeaderComponent";
import RowLabelComponent from "../../components/RowLabelComponent";

function CategoryDetailPage() {
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [model, setModel] = useState({});

  // dialog xóa
  const [dialogDeleteShow, setDialogDeleteShow] = useState(false);

  const fetchDetail = async function () {
    setLoading(true);
    try {
      const res = await axiosClient.get("/cms/category/detail", {
        params: {
          id: id,
        },
      });
      if (res && res.status === 0) {
        setModel(res.data);
      } else {
        toaster.danger(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      toaster.danger(err.message);
    }
    setLoading(false);
  };

  const update = async () => {
    setSubmitting(true);
    try {
      const res = await axiosClient.post("/cms/category/update", {
        id: id,
        name: formik.values.name || "",
        description: formik.values.description || "",
        image: formik.values.image,
        isRecommend: formik.values.isRecommend,
        keyword: [],
        status: formik.values.status || "active",
      });
      if (res && res.status === 0) {
        toaster.success("Đã cập nhật thông tin");
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const remove = async () => {
    setSubmitting(true);
    try {
      const res = await axiosClient.get("/cms/category/delete", {
        params: {
          id: id,
        },
      });
      if (res && res.status === 0) {
        toaster.success("Đã xóa");
        history.push("/category/list");
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      name: model?.name || "",
      description: model?.description || "",
      image: model?.image,
      isRecommend: model?.isRecommend,
      keyword: [""],
      status: model?.status || "active",
    },
    onSubmit: (values) => {
      update();
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      description: Yup.string().required(),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    fetchDetail();
    return () => {};
  }, [id]);

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(4)}
      marginY={majorScale(2)}
      flexDirection="column"
    >
      {loading ? (
        <Spinner />
      ) : (
        <Pane
          display="flex"
          marginY={majorScale(2)}
          paddingX={majorScale(4)}
          paddingY={majorScale(3)}
          flexDirection="column"
          background="white"
        >
          <CornerDialog
            title="Xóa bản ghi?"
            intent="danger"
            isShown={dialogDeleteShow}
            cancelLabel="Hủy"
            confirmLabel="Có xóa"
            onCloseComplete={() => setDialogDeleteShow(false)}
            onConfirm={() => {
              setDialogDeleteShow(false);
              remove();
            }}
          >
            Bạn có chắc chắn muốn xóa bản ghi này?
          </CornerDialog>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            marginBottom={majorScale(2)}
          >
            <HeaderComponent>Thông tin Chuyên mục</HeaderComponent>
          </Pane>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <RowLabelComponent
              leading="ID"
              trailing={
                <Heading size={400} color="teal">
                  {id}
                </Heading>
              }
            />
            <RowLabelComponent
              leading="Loại khuyến nghị"
              trailing={
                <Checkbox
                  label="Là chuyên mục khuyến nghị"
                  checked={formik.values.isRecommend}
                  onChange={(e) =>
                    formik.setFieldValue("isRecommend", e.target.checked)
                  }
                />
              }
            />

            <RowLabelComponent
              leading="Tên chuyên mục"
              trailing={
                <TextInput
                  name="name"
                  placeholder="Nhập tên chuyên mục"
                  type="text"
                  width={550}
                  maxWidth={550}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  isInvalid={formik.errors.name != null}
                />
              }
            />
            <RowLabelComponent
              leading="Mô tả"
              trailing={
                <Textarea
                  name="description"
                  placeholder="Nhập mô tả cho chuyên mục"
                  type="text"
                  width={550}
                  maxWidth={550}
                  height={200}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  isInvalid={formik.errors.description != null}
                />
              }
            />

            <RowLabelComponent
              leading="Trạng thái"
              trailing={
                <Select
                  name="status"
                  width={300}
                  maxWidth={300}
                  size="medium"
                  onChange={formik.handleChange}
                  value={formik.values.status}
                >
                  <option value="active">Hoạt động</option>
                  <option value="block">Bị khóa</option>
                </Select>
              }
            />
            <Divider light={true} />
            <Button
              type="submit"
              isLoading={submitting}
              appearance="primary"
              width={150}
              marginTop={majorScale(3)}
              marginRight={majorScale(3)}
            >
              Cập nhật
            </Button>

            <Button
              type="button"
              intent="danger"
              isLoading={submitting}
              appearance="primary"
              iconBefore={TrashIcon}
              width={150}
              marginTop={majorScale(3)}
              onClick={(e) => setDialogDeleteShow(true)}
            >
              Xóa
            </Button>
          </form>
        </Pane>
      )}
    </Pane>
  );
}

export default CategoryDetailPage;
