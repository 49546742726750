import { Pagination } from "antd";
import {
  AddIcon,
  Avatar,
  Button,
  CloudDownloadIcon,
  Heading,
  majorScale,
  Pane,
  SearchInput,
  Table,
  Text,
  toaster,
  Tooltip,
} from "evergreen-ui";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  formatDate,
  memberTypeLabel,
  statusLabel,
  statusSelftUpdate,
} from "../../../helper/business_helper";
import axiosClient from "../../../network/RestClient";
import LoadingFrame from "../../components/LoadingFrame";

function UsersPage() {
  let { url } = useRouteMatch();
  const history = useHistory();

  const [list, setList] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportExcel, setExportExcel] = useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("updatedAt");

  // phan trang
  const [page, setPage] = useState(1);
  const [totalResult, setTotalResult] = useState(1);
  const limit = 10;

  const fetchData = async function () {
    setLoading(true);
    try {
      const res = await axiosClient.get("/cms/user/search", {
        params: {
          search: search,
          limit: limit,
          sort: sort,
          offset: limit * (page - 1),
        },
      });
      if (res && res.status === 0) {
        setList((old) => [...res.data]);
        setTotalResult(res.totalResult);
      } else {
        toaster.danger(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      toaster.danger(err.message);
    }
    setLoading(false);
  };

  const exportData = async () => {
    try {
      axiosClient({
        url: "/cms/user/export",
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "user.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    } catch (err) {
      toaster.danger(err.message);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, [page, sort]);

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(5)}
      marginY={majorScale(3)}
      flexDirection="column"
    >
      <Pane
        display="flex"
        justifyContent="flex-start"
        alignItems="start"
        flexDirection="row"
      >
        <SearchInput
          placeholder="Tìm kiếm..."
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          marginRight={majorScale(2)}
          width={300}
        />
        <Button type="button" appearance="primary" onClick={(e) => fetchData()}>
          Tìm kiếm
        </Button>

        <Pane display="flex" flexDirection="row" marginLeft="auto">
          <Button
            appearance="primary"
            type="button"
            intent="normal"
            iconBefore={<CloudDownloadIcon />}
            marginRight={majorScale(3)}
            onClick={async (event, done) => {
              setExportExcel(true);
              await exportData();
              setExportExcel(false);
            }}
          >
            Export
          </Button>
          <Button
            appearance="primary"
            type="button"
            intent="danger"
            iconBefore={<AddIcon />}
            onClick={(e) => history.push(url.replace("/list", "/create"))}
          >
            Tạo mới
          </Button>
        </Pane>
      </Pane>
      <Pane
        display="flex"
        justifyContent="flex-start"
        alignItems="start"
        flexDirection="row"
        marginTop={majorScale(2)}
      >
        <Pane
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
          marginRight={majorScale(2)}
        >
          {/* <SelectMenu
            title="Trạng thái"
            options={[
              {
                label: "Ngày cập nhật",
                value: "updatedAt",
              },
              {
                label: "Ngày hết hạn",
                value: "expiredTime",
              },
            ]}
            selected={sort}
            hasFilter={false}
            hasTitle={true}
            onSelect={(item) => setSort(item.value)}
          >
            <Button iconBefore={SortIcon}>Sắp xếp</Button>
          </SelectMenu> */}
        </Pane>
      </Pane>
      <Table marginTop={majorScale(2)}>
        <Table.Head
          height={35}
          color="white"
          background="#0984e3"
          accountForScrollbar={false}
          borderLeft
          borderRight
        >
          <Table.TextHeaderCell flex={0.5}>#</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={4}>Thông tin</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={2}>Loại TK</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={1}>Mã GT</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={1}>Trạng thái</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={1}>TT Profile</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={1.5}>Cập nhật</Table.TextHeaderCell>
        </Table.Head>
        {loading ? (
          <LoadingFrame />
        ) : (
          <Table.Body height={(70 * limit) + 20} maxHeight={(120 * limit) + 20}>
            {list?.map((item, index) => (
              <Table.Row
                key={item._id}
                isSelectable
                borderLeft
                borderRight
                height={70}
                maxHeight={120}
                paddingY={majorScale(1)}
                onSelect={() => {
                  return history.push(
                    url.replace("/list", "/detail") + "/" + item._id
                  );
                }}
              >
                <Table.TextCell flex={0.5}>
                  {index + 1 + (page - 1) * limit}
                </Table.TextCell>
                <Table.TextCell flex={4}>
                  <Tooltip content={item._id}>
                    <Pane
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Avatar
                        size={38}
                        src={item.avatar}
                        name={item.fullName}
                        marginRight={majorScale(2)}
                      />
                      <Pane display="flex" flexDirection="column">
                        <Heading size={400}>{item.fullName}</Heading>
                        <Text size={300}>{item.username}</Text>
                        <Text size={300}>
                          {item.email || "Chưa có thông tin"}
                        </Text>
                      </Pane>
                    </Pane>
                  </Tooltip>
                </Table.TextCell>

                <Table.TextCell flex={2}>
                  <Pane display="flex" flexDirection="column">
                    <Text size={200}>{memberTypeLabel(item.memberType)}</Text>
                    <Text size={200} color="blue">
                      {item.memberType === "premium" ||
                      item.memberType === "platium"
                        ? formatDate(item.expireDate, "DD/MM/YYYY")
                        : ""}
                    </Text>
                  </Pane>
                </Table.TextCell>
                <Table.TextCell flex={1}>{item.userInvite}</Table.TextCell>
                <Table.TextCell flex={1}>
                  {statusLabel(item.status)}
                </Table.TextCell>
                <Table.TextCell flex={1}>
                  {statusSelftUpdate(item.seftUpdated)}
                </Table.TextCell>
                <Table.TextCell flex={1.5}>
                  {formatDate(item.updatedAt)}
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>
      <Pagination
        current={page}
        pageSize={limit}
        total={totalResult}
        alignSelf="end"
        showSizeChanger={false}
        marginTop={majorScale(2)}
        onChange={(value) => {
          setPage(value);
        }}
      />
    </Pane>
  );
}

export default UsersPage;
