import { Divider } from "@material-ui/core";
import { DatePicker } from "antd";
import {
  Button,
  Heading,
  LockIcon,
  majorScale,
  Pane,
  Select,
  Spinner,
  TextInput,
  toaster,
  TrashIcon,
  Dialog,
  CornerDialog,
} from "evergreen-ui";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import axiosClient from "../../../network/RestClient";
import HeaderComponent from "../../components/HeaderComponent";
import RowLabelComponent from "../../components/RowLabelComponent";
import locale from "antd/lib/locale/vi_VN";
import moment from "moment";

function UserDetailPage() {
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [model, setModel] = useState({});

  //dialog đổi mật khẩu
  const [dialogShow, setDialogShow] = useState(false);
  const [password, setPassword] = useState("");

  // dialog xóa
  const [dialogDeleteShow, setDialogDeleteShow] = useState(false);

  const fetchDetail = async function () {
    setLoading(true);
    try {
      const res = await axiosClient.get("/cms/user/detail", {
        params: {
          id: id,
        },
      });
      if (res && res.status === 0) {
        setModel(res.data);
      } else {
        toaster.danger(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      toaster.danger(err.message);
    }
    setLoading(false);
  };

  const update = async () => {
    setSubmitting(true);

    // quan trọng, cần transform lại ngày tháng về định dạng 23:00 DD/MM/YYYY
    let expireDate = null;
    if (formik.values.expireDate != null) {
      const m = moment(formik.values.expireDate);
      m.set({ h: 23, m: 59 });
      expireDate = m.toDate();
    }

    let birthday = null;
    if (formik.values.birthday) {
      const m = moment(formik.values.birthday);
      m.set({ h: 23, m: 0 });
      birthday = m.toDate();
    }

    try {
      const res = await axiosClient.post("/cms/user/update", {
        id: id,
        username: formik.values.username,
        avatar: formik.values.avatar || "",
        fullName: formik.values.fullName,
        gender: formik.values.gender,
        address: formik.values.address,
        email: formik.values.email,
        phone: formik.values.phone,
        birthday: birthday,
        stockId: formik.values.stockId,
        memberType: formik.values.memberType,
        expireDate: formik.values.memberType === "free" ? null : expireDate,
        status: formik.values.status,
        seftUpdated: formik.values.seftUpdated || false,
      });
      if (res && res.status === 0) {
        toaster.success("Đã cập nhật thông tin");
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const changePassword = async () => {
    setSubmitting(true);
    try {
      if (password.length < 6) {
        toaster.danger("Mật khẩu yêu cầu > 6 kí tự");
      } else {
        const res = await axiosClient.post("/cms/user/change-password", {
          id: id,
          newPassword: password,
        });
        if (res && res.status === 0) {
          toaster.success("Đã cập nhật mật khẩu");
        } else {
          toaster.danger(res.message ?? "Có lỗi");
        }
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setDialogShow(false);
    setSubmitting(false);
  };

  const remove = async () => {
    setSubmitting(true);
    try {
      const res = await axiosClient.get("/cms/user/delete", {
        params: {
          id: id,
        },
      });
      if (res && res.status === 0) {
        toaster.success("Đã xóa");
        history.push("/user/list");
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      username: model?.username || "",
      avatar: model?.avatar || "",
      fullName: model?.fullName || "",
      gender: model?.gender || "M",
      address: model?.address || "",
      email: model?.email || "",
      phone: model?.phone || "",
      birthday: model?.birthday,
      stockId: model?.stockId || "",
      userInvite: model?.userInvite,
      memberType: model?.memberType || "free",
      expireDate: model?.expireDate,
      status: model?.status || "active",
      seftUpdated: model?.seftUpdated || false,
    },

    onSubmit: (values) => {
      update();
    },
    validationSchema: Yup.object({
      username: Yup.string().required().min(4),
      fullName: Yup.string().required().min(4),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    fetchDetail();
    return () => {};
  }, [id]);

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(4)}
      marginY={majorScale(2)}
      flexDirection="column"
    >
      {loading ? (
        <Spinner />
      ) : (
        <Pane
          display="flex"
          marginY={majorScale(2)}
          paddingX={majorScale(4)}
          paddingY={majorScale(3)}
          flexDirection="column"
          background="white"
        >
          <Dialog
            isShown={dialogShow}
            title="Đổi mật khẩu"
            intent="danger"
            confirmLabel="Đổi mật khẩu"
            cancelLabel="Hủy"
            onCloseComplete={() => setDialogShow(false)}
            onConfirm={() => changePassword()}
          >
            <RowLabelComponent
              leading="Mật khẩu mới:"
              trailing={
                <TextInput
                  name="password"
                  placeholder="Nhập mật khẩu mới từ 6-20 kí tự"
                  type="text"
                  width={200}
                  maxWidth={200}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              }
            />
          </Dialog>

          <CornerDialog
            title="Xóa bản ghi?"
            intent="danger"
            isShown={dialogDeleteShow}
            cancelLabel="Hủy"
            confirmLabel="Có xóa"
            onCloseComplete={() => setDialogDeleteShow(false)}
            onConfirm={() => {
              setDialogDeleteShow(false);
              remove();
            }}
          >
            Bạn có chắc chắn muốn xóa bản ghi này?
          </CornerDialog>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            marginBottom={majorScale(2)}
          >
            <HeaderComponent>Thông tin Người dùng</HeaderComponent>
          </Pane>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <RowLabelComponent
              leading="ID"
              trailing={
                <Heading size={400} color="teal">
                  {id}
                </Heading>
              }
            />
            <RowLabelComponent
              leading="Tên đăng nhập"
              trailing={
                <TextInput
                  name="username"
                  placeholder="Tên đăng nhập duy nhất"
                  type="text"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  isInvalid={formik.errors.username != null}
                />
              }
            />
            <RowLabelComponent
              leading=""
              trailing={
                <Button
                  type="button"
                  isLoading={submitting}
                  width={150}
                  iconBefore={LockIcon}
                  onClick={(e) => setDialogShow(true)}
                >
                  Đặt lại mật khẩu
                </Button>
              }
            />

            <Divider light={true} />
            <RowLabelComponent
              leading="Họ tên"
              trailing={
                <TextInput
                  name="fullName"
                  placeholder="Nhập đầy đủ họ tên"
                  type="text"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                  isInvalid={formik.errors.fullName != null}
                />
              }
            />
            <RowLabelComponent
              leading="Giới tính"
              trailing={
                <Select
                  name="gender"
                  width={300}
                  maxWidth={300}
                  size="medium"
                  onChange={formik.handleChange}
                  value={formik.values.gender}
                >
                  <option value="M">Nam</option>
                  <option value="F">Nữ</option>
                </Select>
              }
            />
            <RowLabelComponent
              leading="Ngày sinh"
              trailing={
                <DatePicker
                  locale={locale}
                  format="DD/MM/YYYY"
                  style={{ width: "300px" }}
                  placeholder="Chọn ngày sinh"
                  bordered="true"
                  selected={
                    formik.values.birthday
                      ? moment(formik.values.birthday)
                      : null
                  }
                  defaultValue={
                    formik.values.birthday
                      ? moment(formik.values.birthday)
                      : null
                  }
                  onChange={(date) => {
                    if (date) {
                      formik.setFieldValue("birthday", date.toDate(), true);
                    } else {
                      formik.setFieldValue("birthday", null);
                    }
                  }}
                />
              }
            />

            <RowLabelComponent
              leading="Địa chỉ"
              trailing={
                <TextInput
                  name="address"
                  placeholder="Địa chỉ liên hệ"
                  type="text"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                />
              }
            />
            <RowLabelComponent
              leading="Email"
              trailing={
                <TextInput
                  name="email"
                  placeholder="Địa chỉ email"
                  type="email"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              }
            />
            <RowLabelComponent
              leading="Số ĐT"
              trailing={
                <TextInput
                  name="phone"
                  placeholder="Số điện thoại liên hệ"
                  type="phone"
                  width={300}
                  disabled={true}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                />
              }
            />
            <RowLabelComponent
              leading="Tài khoản FPTS"
              trailing={
                <TextInput
                  name="stockId"
                  placeholder="FPTS account"
                  type="text"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.stockId}
                />
              }
            />

            <RowLabelComponent
              leading="Mã giới thiệu"
              trailing={
                <TextInput
                  name="userInvite"
                  placeholder="Mã code người giới thiệu"
                  type="text"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.userInvite}
                />
              }
            />

            <Divider light={true} />
            <RowLabelComponent
              leading="Loại tài khoản"
              trailing={
                <Select
                  name="memberType"
                  width={300}
                  maxWidth={300}
                  size="medium"
                  onChange={formik.handleChange}
                  value={formik.values.memberType}
                >
                  <option value="free">Member</option>
                  <option value="premium">Hạng Gold</option>
                  <option value="platium">Hạng Platium</option>
                </Select>
              }
            />

            {formik.values.memberType === "free" ? (
              <div />
            ) : (
              <RowLabelComponent
                leading="Thời hạn gói thuê bao"
                trailing={
                  <DatePicker
                    locale={locale}
                    format="DD/MM/YYYY"
                    placeholder="Chọn ngày hết hạn gói thuê bao"
                    bordered="true"
                    style={{ width: "300px" }}
                    disabledDate={(current) => {
                      return moment().add(-1, "days") >= current;
                    }}
                    selected={
                      formik.values.expireDate
                        ? moment(formik.values.expireDate)
                        : null
                    }
                    defaultValue={
                      formik.values.expireDate
                        ? moment(formik.values.expireDate)
                        : null
                    }
                    onChange={(date) => {
                      if (date) {
                        formik.setFieldValue("expireDate", date.toDate(), true);
                      } else {
                        formik.setFieldValue("expireDate", null);
                      }
                    }}
                  />
                }
              />
            )}
            <Divider light={true} />
            <RowLabelComponent
              leading="Trạng thái"
              trailing={
                <Select
                  name="status"
                  width={300}
                  maxWidth={300}
                  size="medium"
                  onChange={formik.handleChange}
                  value={formik.values.status}
                >
                  <option value="active">Hoạt động</option>
                  <option value="block">Bị khóa</option>
                </Select>
              }
            />

            <RowLabelComponent
              leading="Trạng thái Profile"
              trailing={
                <Select
                  name="seftUpdated"
                  width={300}
                  maxWidth={300}
                  size="medium"
                  onChange={(e) => {
                    formik.setFieldValue("seftUpdated", e.target.value);
                  }}
                  value={formik.values.seftUpdated}
                >
                  <option value={true}>Đã hoàn thiện</option>
                  <option value={false}>Chưa cập nhật</option>
                </Select>
              }
            />
            <Divider light={true} />
            <Button
              type="submit"
              isLoading={submitting}
              appearance="primary"
              width={150}
              marginTop={majorScale(3)}
              marginRight={majorScale(3)}
            >
              Cập nhật
            </Button>
            <Button
              type="button"
              intent="danger"
              isLoading={submitting}
              appearance="primary"
              iconBefore={TrashIcon}
              width={150}
              marginTop={majorScale(3)}
              onClick={(e) => setDialogDeleteShow(true)}
            >
              Xóa
            </Button>
          </form>
        </Pane>
      )}
    </Pane>
  );
}

export default UserDetailPage;
