import { Heading, majorScale, Pane, Text } from "evergreen-ui";

function RowLabelComponent(props) {
  return (
    <Pane
      display="flex"
      justifyContent="space-between"
      alignItems={props.alignItems || "center"}
      flexDirection="row"
      marginY={majorScale(2)}
    >
      <Pane flex={1} minWidth={100}>
        <Heading size={400}>{props.leading}</Heading>
      </Pane>
      <Pane flex={7}>
        <Text size={300}>
          {props.trailing ? props.trailing : "Chưa có dữ liệu"}
        </Text>
      </Pane>
    </Pane>
  );
}

export default RowLabelComponent;
