import { Divider } from "@material-ui/core";
import { DatePicker } from "antd";
import locale from "antd/lib/locale/vi_VN";
import {
  Button,
  Heading,
  majorScale,
  Pane,
  SelectField,
  Spinner,
  TextInputField,
} from "evergreen-ui";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import * as Yup from "yup";
import HeaderComponent from "../../components/HeaderComponent";

function ShortTermDetail(props) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      stockCode: props?.model?.stockCode || "",
      recommendDate: props?.model?.recommendDate || null,
      recommendPrice: props?.model?.recommendPrice || "0",
      currentPrice: props?.model?.currentPrice || "0",
      targetPrice: props?.model?.targetPrice || "0",
      profitRate: props?.model?.profitRate || "0",
      type: props?.model?.type || "",
      action: props?.model?.action || "buy",
      actionNote: props?.model?.actionNote || "",
      note: props?.model?.note || "",
    },
    onSubmit: (values) => {
      props.onChange(formik.values);
    },
    validationSchema: Yup.object({
      stockCode: Yup.string().required(),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(2)}
      marginY={majorScale(2)}
      flexDirection="column"
    >
      {loading ? (
        <Spinner />
      ) : (
        <Pane
          display="flex"
          marginY={majorScale(2)}
          paddingX={majorScale(2)}
          paddingY={majorScale(2)}
          flexDirection="column"
          background="white"
        >
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            marginBottom={majorScale(2)}
          >
            <HeaderComponent>Khuyến nghị ngắn hạn</HeaderComponent>
          </Pane>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <Pane display="flex" flexDirection="row" flex={1}>
              <TextInputField
                name="stockCode"
                label="Mã CK"
                placeholder="Nhập mã chứng khoán"
                type="text"
                width={250}
                maxwidth={400}
                marginY={majorScale(2)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.stockCode}
                validationMessage={formik.errors.stockCode}
              />
              <Pane
                display="flex"
                flexDirection="column"
                flex={1}
                marginLeft={majorScale(3)}
              >
                <Heading size={400} marginTop={13} marginBottom={10}>
                  Ngày khuyến nghị
                </Heading>
                <DatePicker
                  locale={locale}
                  format="DD/MM/YYYY"
                  placeholder="Chọn ngày khuyến nghị"
                  bordered="true"
                  style={{ width: "250px" }}
                  selected={
                    formik.values.recommendDate
                      ? moment(formik.values.recommendDate)
                      : null
                  }
                  defaultValue={
                    formik.values.recommendDate
                      ? moment(formik.values.recommendDate)
                      : null
                  }
                  onChange={(date) => {
                    if (date) {
                      formik.setFieldValue(
                        "recommendDate",
                        date.toDate(),
                        true
                      );
                    } else {
                      formik.setFieldValue("recommendDate", null);
                    }
                  }}
                />
              </Pane>
            </Pane>
            <Pane display="flex" flexDirection="row" flex={1}>
              <TextInputField
                name="recommendPrice"
                label="Giá khuyến nghị"
                placeholder="Nhập giá khuyến nghị"
                type="number"
                step="0.1"
                width={250}
                maxwidth={400}
                marginY={majorScale(2)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.recommendPrice}
                validationMessage={formik.errors.recommendPrice}
              />
              <TextInputField
                name="currentPrice"
                label="Giá hiện tại"
                placeholder="Nhập giá hiện tại"
                type="number"
                step="0.1"
                width={250}
                maxwidth={400}
                marginY={majorScale(2)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.currentPrice}
                validationMessage={formik.errors.currentPrice}
                flex={1}
                marginLeft={majorScale(3)}
              />
            </Pane>

            <Pane display="flex" flexDirection="row" flex={1}>
              <TextInputField
                name="targetPrice"
                label="Định giá"
                placeholder="Nhập định giá"
                type="number"
                step="0.1"
                width={250}
                maxwidth={400}
                marginY={majorScale(2)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.targetPrice}
                validationMessage={formik.errors.targetPrice}
                flex={1}
              />
              <TextInputField
                name="profitRate"
                label="Sinh lời hiện tại"
                placeholder="Nhập sinh lời"
                type="text"
                width={250}
                maxwidth={400}
                marginY={majorScale(2)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.profitRate}
                validationMessage={formik.errors.profitRate}
                flex={1}
                marginLeft={majorScale(3)}
              />
            </Pane>

            <Pane display="flex" flexDirection="row" flex={1}>
              <TextInputField
                name="actionNote"
                label="Trạng thái khuyến nghị"
                placeholder="Nhập trạng thái khuyến nghị"
                type="text"
                width={250}
                maxwidth={400}
                marginY={majorScale(2)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.actionNote}
                validationMessage={formik.errors.actionNote}
                flex={1}
              />
              <SelectField
                name="action"
                label="Loại trạng thái"
                width={250}
                maxwidth={400}
                size="medium"
                marginY={majorScale(2)}
                onChange={formik.handleChange}
                value={formik.values.action}
                flex={1}
                marginLeft={majorScale(3)}
              >
                <option value="buy">Mua vào</option>
                <option value="hold">Nắm giữ</option>
                <option value="close">Đóng lại</option>
              </SelectField>
            </Pane>

            <Pane display="flex" flexDirection="row" flex={1}>
              <TextInputField
                name="note"
                label="Thời gian nắm giữ"
                placeholder="Nhập thời gian nắm giữ"
                type="text"
                width={250}
                maxwidth={400}
                marginY={majorScale(2)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.note}
                validationMessage={formik.errors.note}
                flex={1}
              />
              <TextInputField
                name="type"
                label="Phân loại CP"
                placeholder="Nhập phân loại cổ phiếu"
                type="text"
                width={250}
                maxwidth={400}
                marginY={majorScale(2)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.type}
                validationMessage={formik.errors.type}
                flex={1}
                marginLeft={majorScale(3)}
              />
            </Pane>
            <Divider light={true} />
            <Button
              type="submit"
              isLoading={submitting}
              appearance="primary"
              width={150}
              marginTop={majorScale(3)}
              marginRight={majorScale(2)}
            >
              Cập nhật
            </Button>
            {props?.model ? (
              <Button
                type="button"
                isLoading={submitting}
                appearance="primary"
                intent="danger"
                width={150}
                marginTop={majorScale(3)}
                onClick={(e) => props.onRemove()}
              >
                Xóa
              </Button>
            ) : (
              <Pane></Pane>
            )}
          </form>
        </Pane>
      )}
    </Pane>
  );
}
export default ShortTermDetail;
