import { Route, Switch } from "react-router-dom";
import UserDetailPage from "./detail";
import UsersPage from "./list";
import UserCreatePage from "./create";
import NoPermissionFrame from "../../components/NoPermission";
import { adminAtom } from "../../../data/admin_atoms";
import { useRecoilValue } from "recoil";

function UserRouter() {
  const admin = useRecoilValue(adminAtom);
  return admin.role === "manager" ? (
    <NoPermissionFrame />
  ) : (
    <Switch>
      <Route key="/user/list" path="/user/list" component={UsersPage} />
      <Route key="/user/create" path="/user/create" component={UserCreatePage} />
      <Route
        key="/user/detail/:id"
        path="/user/detail/:id"
        component={UserDetailPage}
      />
    </Switch>
  );
}

export default UserRouter;
