import { Divider } from "@material-ui/core";
import {
  Button,
  CornerDialog,
  Heading,
  majorScale,
  Pane,
  Select,
  Spinner,
  TagInput,
  Textarea,
  TextInput,
  toaster,
  TrashIcon,
} from "evergreen-ui";
import { useFormik } from "formik";
import ImageUpload from "image-upload-react";
import "image-upload-react/dist/index.css";
import JoditEditor from "jodit-react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import axiosClient from "../../../network/RestClient";
import HeaderComponent from "../../components/HeaderComponent";
import RowLabelComponent from "../../components/RowLabelComponent";

function NewsDetailPage() {
  const history = useHistory();
  const { id } = useParams();

  const [config, setConfig] = useState({
    readonly: false,
    toolbar: true,
    minHeight: 550,
    maxHeight: 1500,
    maxWidth: "70vw",
    padding: 0,
    style: {
      background: "white",
    }
  });

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [model, setModel] = useState({});
  const [categoryList, setCategoryList] = useState([]);

  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    if (!image) setImageFile(null);
  }, [image]);

  // dialog xóa
  const [dialogDeleteShow, setDialogDeleteShow] = useState(false);

  const fetchDetail = async function () {
    setLoading(true);
    try {
      const res = await axiosClient.get("/cms/news/detail", {
        params: {
          id: id,
        },
      });
      if (res && res.status === 0) {
        setModel(res.data);
        setImage(res.data.image);
      } else {
        toaster.danger(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      toaster.danger(err.message);
    }
    setLoading(false);
  };

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const res = await axiosClient.get("/cms/category/search", {
        params: {
          search: "",
        },
      });
      setCategoryList([]);
      if (res && res.status === 0) {
        setCategoryList((old) => [...old, ...res.data]);
      } else {
        toaster.danger(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      toaster.danger(err.message);
    }
    setLoading(false);
  };

  const update = async () => {
    setSubmitting(true);
    try {
      let imageLink = "";
      if (imageFile) {
        const formData = new FormData();
        formData.append("file", imageFile);
        formData.append("fileName", `news_${id}`);
        const res1 = await axiosClient.post(
          "/cms/media/upload-image2",
          formData,
          {}
        );
        if (res1.status === 0) {
          imageLink = res1.data;
        }
      } else {
        imageLink = model?.image;
      }
      const res = await axiosClient.post("/cms/news/update", {
        id: id,
        title: formik.values.title || "",
        sapo: formik.values.sapo || "",
        content: formik.values.content,
        author: formik.values.author,
        image: imageLink,
        keyword: formik.values.keyword,
        attachments: formik.values.attachments,
        categoryRef: formik.values.categoryRef,
        accessType: formik.values.accessType,
        status: formik.values.status,
      });
      if (res && res.status === 0) {
        toaster.success("Đã cập nhật thông tin");
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const remove = async () => {
    setSubmitting(true);
    try {
      const res = await axiosClient.get("/cms/news/delete", {
        params: {
          id: id,
        },
      });
      if (res && res.status === 0) {
        toaster.success("Đã xóa");
        history.push("/news/list");
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      title: model?.title || "",
      sapo: model?.sapo || "",
      content: model?.content,
      author: model?.author,
      image: model?.image,
      keyword: model?.keyword
        ? model?.keyword.map((e) => e.toUpperCase())
        : [""],
      attachments: model?.attachments,
      categoryRef: model?.categoryRef?._id,
      accessType: model?.accessType,
      status: model?.status,
    },
    onSubmit: (values) => {
      update();
    },

    validationSchema: Yup.object({
      title: Yup.string().required(),
      sapo: Yup.string().required(),
      categoryRef: Yup.string().required(),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    fetchCategories();
    fetchDetail();
    return () => {};
  }, [id]);

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(4)}
      marginY={majorScale(2)}
      flexDirection="column"
    >
      {loading ? (
        <Spinner />
      ) : (
        <Pane
          display="flex"
          marginY={majorScale(2)}
          paddingX={majorScale(4)}
          paddingY={majorScale(3)}
          flexDirection="column"
          background="white"
        >
          <CornerDialog
            title="Xóa bản ghi?"
            intent="danger"
            isShown={dialogDeleteShow}
            cancelLabel="Hủy"
            confirmLabel="Có xóa"
            onCloseComplete={() => setDialogDeleteShow(false)}
            onConfirm={() => {
              setDialogDeleteShow(false);
              remove();
            }}
          >
            Bạn có chắc chắn muốn xóa bản ghi này?
          </CornerDialog>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            marginBottom={majorScale(2)}
          >
            <HeaderComponent>Thông tin Bài viết</HeaderComponent>
          </Pane>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <RowLabelComponent
              leading="ID"
              trailing={
                <Heading size={400} color="teal">
                  {id}
                </Heading>
              }
            />

            <RowLabelComponent
              leading="Danh mục"
              trailing={
                <Select
                  name="categoryRef"
                  required
                  width={300}
                  maxWidth={300}
                  size="medium"
                  onChange={formik.handleChange}
                  value={formik.values.categoryRef}
                >
                  {categoryList.map((el, index) => (
                    <option key={index} value={el._id.toString()}>
                      {el.name}
                    </option>
                  ))}
                </Select>
              }
            />
            <RowLabelComponent
              leading="Quyền xem"
              trailing={
                <Select
                  name="accessType"
                  required
                  width={300}
                  maxWidth={300}
                  size="medium"
                  defaultValue="member"
                  onChange={formik.handleChange}
                  value={formik.values.accessType}
                >
                  <option key="free" value="free">
                    Miễn phí
                  </option>
                  <option key="member" value="member">
                    Member thường
                  </option>
                  <option key="premium" value="premium">
                    Thành viên hạng Gold
                  </option>
                  <option key="platium" value="platium">
                    Thành viên hạng Platium
                  </option>
                </Select>
              }
            />
            <RowLabelComponent
              leading="Tiêu đề"
              trailing={
                <TextInput
                  name="title"
                  placeholder="Nhập tiêu đề bài viết"
                  type="text"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  isInvalid={formik.errors.title != null}
                />
              }
            />
            <RowLabelComponent
              leading="Mô tả ngắn"
              trailing={
                <Textarea
                  name="sapo"
                  placeholder="Nhập mô tả ngắn"
                  type="text"
                  width="50vw"
                  maxWidth={450}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sapo}
                  isInvalid={formik.errors.sapo != null}
                />
              }
            />

            <Divider light={true} />
            <RowLabelComponent
              leading="Nội dung"
              alignItems="flex-start"
              trailing={
                <JoditEditor
                  config={config}
                  onChange={(value) => formik.setFieldValue("content", value)}
                  // onBlur={handleBlurAreaChange}
                  value={formik.values.content}
                />
              }
            />

            <Divider light={true} />
            <RowLabelComponent
              leading="Từ khóa"
              trailing={
                <TagInput
                  inputProps={{ placeholder: "Thêm mã chứng khoán" }}
                  width="50vw"
                  spellCheck="false"
                  values={formik.values.keyword}
                  onChange={(values) => {
                    formik.setFieldValue(
                      "keyword",
                      values.map((e) => e.toUpperCase())
                    );
                  }}
                  onBlur={formik.handleBlur}
                />
              }
            />
            <Divider light={true} />
            <RowLabelComponent
              leading="Trạng thái"
              trailing={
                <Select
                  name="status"
                  width={300}
                  maxWidth={300}
                  size="medium"
                  onChange={formik.handleChange}
                  value={formik.values.status}
                >
                  <option value="active">Hoạt động</option>
                  <option value="block">Bị khóa</option>
                </Select>
              }
            />
            <Divider light={true} />
            <RowLabelComponent
              leading="Ảnh đại diện"
              trailing={
                <ImageUpload
                  handleImageSelect={(e) => {
                    setImageFile(e.target.files[0]);
                    const url = URL.createObjectURL(e.target.files[0]);
                    setImage(url);
                  }}
                  defaultDeleteIconSize={50}
                  defaultDeleteIconColor="#222222"
                  deleteIcon={<div color="white">🗑️</div>}
                  imageSrc={image}
                  setImageSrc={setImage}
                  style={{
                    margin: 0,
                    width: 160,
                    height: 100,
                    background: "grey",
                    objectFit: "cover",
                  }}
                />
              }
            />
            <Divider light={true} />
            <Button
              type="submit"
              isLoading={submitting}
              appearance="primary"
              width={150}
              marginTop={majorScale(3)}
              marginRight={majorScale(3)}
            >
              Cập nhật
            </Button>

            <Button
              type="button"
              intent="danger"
              isLoading={submitting}
              appearance="primary"
              iconBefore={TrashIcon}
              width={150}
              marginTop={majorScale(3)}
              onClick={(e) => setDialogDeleteShow(true)}
            >
              Xóa
            </Button>
          </form>
        </Pane>
      )}
    </Pane>
  );
}

export default NewsDetailPage;
