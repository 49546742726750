import { Route, Switch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { adminAtom } from "../../../data/admin_atoms";
import NoPermissionFrame from "../../components/NoPermission";
import ServiceCreatePage from "./create";
import ServiceDetailPage from "./detail";
import ServicesPage from "./list";

function ServiceRouter() {
  const admin = useRecoilValue(adminAtom);
  return admin.role === "manager" ? (
    <NoPermissionFrame />
  ) : (
    <Switch>
      <Route
        key="/service/list"
        path="/service/list"
        component={ServicesPage}
      />
      <Route
        key="/service/detail/:id"
        path="/service/detail/:id"
        component={ServiceDetailPage}
      />
      <Route
        key="/service/create"
        path="/service/create"
        component={ServiceCreatePage}
      />
    </Switch>
  );
}

export default ServiceRouter;
