import { Divider } from "antd";
import {
  Button,
  majorScale,
  Pane,
  Spinner,
  TextInput,
  toaster,
} from "evergreen-ui";
import { useFormik } from "formik";
import JoditEditor from "jodit-react";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import * as Yup from "yup";
import { adminAtom } from "../../../data/admin_atoms";
import axiosClient from "../../../network/RestClient";
import HeaderComponent from "../../components/HeaderComponent";
import NoPermissionFrame from "../../components/NoPermission";
import RowLabelComponent from "../../components/RowLabelComponent";

function ContactPage() {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [model, setModel] = useState(false);
  const admin = useRecoilValue(adminAtom);

  const [config, setConfig] = useState({
    readonly: false,
    toolbar: true,
    minHeight: 550,
    maxHeight: 1500,
    maxWidth: "60vw",
    style: {
      background: "white",
    }
  });

  const fetchDetail = async () => {
    setLoading(true);
    try {
      const res = await axiosClient.get("/cms/contact/detail", {
        params: {},
      });
      if (res && res.status === 0) {
        setModel(res.data);
      } else {
        toaster.danger(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      toaster.danger(err.message);
    }
    setLoading(false);
  };

  const update = async () => {
    setSubmitting(true);
    try {
      const res = await axiosClient.post("/cms/contact/update", {
        id: model._id,
        title: formik.values.title,
        phone: formik.values.phone,
        email: formik.values.email,
        website: formik.values.website,
        fanpage: formik.values.fanpage,
        youtube: formik.values.youtube,
        telegram: formik.values.telegram,
        zalo: formik.values.zalo,
        paymentNote: formik.values.paymentNote,
      });
      if (res && res.status === 0) {
        toaster.success("Đã cập nhật thông tin");
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      title: model?.title,
      phone: model?.phone,
      email: model?.email,
      website: model?.website,
      fanpage: model?.fanpage,
      youtube: model?.youtube,
      telegram: model?.telegram,
      zalo: model?.zalo,
      paymentNote: model?.paymentNote,
    },
    onSubmit: (values) => {
      update();
    },
    validationSchema: Yup.object({
      title: Yup.string().required(),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    fetchDetail();
    return () => {};
  }, []);
  return admin.role === "manager" ? (
    <NoPermissionFrame />
  ) : (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(4)}
      marginY={majorScale(2)}
      flexDirection="column"
    >
      {loading ? (
        <Spinner />
      ) : (
        <Pane
          display="flex"
          marginY={majorScale(2)}
          paddingX={majorScale(4)}
          paddingY={majorScale(3)}
          flexDirection="column"
          background="white"
        >
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            marginBottom={majorScale(0)}
          >
            <HeaderComponent>Thông tin liên hệ</HeaderComponent>
          </Pane>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <RowLabelComponent
              leading="Tiêu đề"
              trailing={
                <TextInput
                  name="title"
                  placeholder="Nhập tiêu đề"
                  type="text"
                  width={450}
                  maxWidth={450}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                />
              }
            />
            <RowLabelComponent
              leading="Số điện thoại"
              trailing={
                <TextInput
                  name="phone"
                  placeholder=""
                  type="text"
                  width={450}
                  maxWidth={450}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
              }
            />
            <RowLabelComponent
              leading="Email"
              trailing={
                <TextInput
                  name="email"
                  placeholder=""
                  type="text"
                  width={450}
                  maxWidth={450}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              }
            />
            <RowLabelComponent
              leading="Website"
              trailing={
                <TextInput
                  name="website"
                  placeholder=""
                  type="text"
                  width={450}
                  maxWidth={450}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.website}
                />
              }
            />
            <RowLabelComponent
              leading="Fanpage FB"
              trailing={
                <TextInput
                  name="fanpage"
                  placeholder=""
                  type="text"
                  width={450}
                  maxWidth={450}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fanpage}
                />
              }
            />
            <RowLabelComponent
              leading="Youtube"
              trailing={
                <TextInput
                  name="youtube"
                  placeholder=""
                  type="text"
                  width={450}
                  maxWidth={450}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.youtube}
                />
              }
            />
            <RowLabelComponent
              leading="Telegram"
              trailing={
                <TextInput
                  name="telegram"
                  placeholder=""
                  type="text"
                  width={450}
                  maxWidth={450}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.telegram}
                />
              }
            />
            <RowLabelComponent
              leading="Zalo"
              trailing={
                <TextInput
                  name="zalo"
                  placeholder=""
                  type="text"
                  width={450}
                  maxWidth={450}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.zalo}
                />
              }
            />
            <Divider light={true} />
            <RowLabelComponent
              leading="Thông tin thanh toán"
              alignItems="flex-start"
              trailing={
                <JoditEditor
                  config={config}
                  onChange={(value) =>
                    formik.setFieldValue("paymentNote", value)
                  }
                  value={formik.values.paymentNote}
                />
              }
            />

            <Divider light={true} />
            <Button
              type="submit"
              isLoading={submitting}
              appearance="primary"
              width={150}
              marginTop={majorScale(1)}
            >
              Cập nhật
            </Button>
          </form>
        </Pane>
      )}
    </Pane>
  );
}

export default ContactPage;
