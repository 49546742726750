import { Divider } from "@material-ui/core";
import { DatePicker } from "antd";
import locale from "antd/lib/locale/vi_VN";
import {
  Button,
  majorScale,
  Pane,
  Select,
  Spinner,
  TextInput,
  toaster,
} from "evergreen-ui";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import axiosClient from "../../../network/RestClient";
import HeaderComponent from "../../components/HeaderComponent";
import RowLabelComponent from "../../components/RowLabelComponent";

function UserCreatePage() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const create = async () => {
    let expireDate = null;
    if (formik.values.expireDate) {
      const m = moment(formik.values.expireDate);
      m.set({ h: 23, m: 59 });
      expireDate = m.toDate();
    }

    let birthday = null;
    if (formik.values.birthday) {
      const m = moment(formik.values.birthday);
      m.set({ h: 23, m: 0 });
      birthday = m.toDate();
    }

    setSubmitting(true);
    try {
      const res = await axiosClient.post("/cms/user/create", {
        username: formik.values.username,
        password: formik.values.password,
        avatar: formik.values.avatar || "",
        fullName: formik.values.fullName,
        gender: formik.values.gender,
        address: formik.values.address,
        email: formik.values.email,
        phone: formik.values.phone,
        birthday: birthday,
        stockId: formik.values.stockId,
        memberType: formik.values.memberType,
        expireDate: formik.values.memberType === "free" ? null : expireDate,
        status: "active",
      });
      if (res && res.status === 0) {
        toaster.success("Đã tạo mới");
        history.push("/user/list");
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      avatar: "",
      fullName: "",
      gender: "M",
      address: "",
      email: "",
      phone: "",
      birthday: null,
      stockId: "",
      userInvite: null,
      memberType: "free",
      expireDate: null,
      status: "active",
    },
    onSubmit: (values) => {
      create();
    },
    validationSchema: Yup.object({
      username: Yup.string().required().min(4),
      password: Yup.string().required().min(6),
      fullName: Yup.string().required().min(4),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(4)}
      marginY={majorScale(2)}
      flexDirection="column"
    >
      {loading ? (
        <Spinner />
      ) : (
        <Pane
          display="flex"
          marginY={majorScale(2)}
          paddingX={majorScale(4)}
          paddingY={majorScale(3)}
          flexDirection="column"
          background="white"
        >
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            marginBottom={majorScale(2)}
          >
            <HeaderComponent>Tạo mới Người dùng</HeaderComponent>
          </Pane>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <RowLabelComponent
              leading="Tên đăng nhập (*)"
              trailing={
                <TextInput
                  name="username"
                  placeholder="Tên đăng nhập duy nhất, > 4 kí tự"
                  type="text"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  isInvalid={formik.errors.username != null}
                />
              }
            />
            <RowLabelComponent
              leading="Mật khẩu (*)"
              trailing={
                <TextInput
                  name="password"
                  placeholder="Mật khẩu đăng nhập > 6 kí tự"
                  type="text"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  isInvalid={formik.errors.password != null}
                />
              }
            />
            <Divider light={true} />
            <RowLabelComponent
              leading="Họ tên"
              trailing={
                <TextInput
                  name="fullName"
                  placeholder="Nhập đầy đủ họ tên"
                  type="text"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                  isInvalid={formik.errors.fullName != null}
                />
              }
            />
            <RowLabelComponent
              leading="Giới tính"
              trailing={
                <Select
                  name="gender"
                  width={300}
                  maxWidth={300}
                  size="medium"
                  onChange={formik.handleChange}
                  value={formik.values.gender}
                >
                  <option value="M">Nam</option>
                  <option value="F">Nữ</option>
                </Select>
              }
            />
            <RowLabelComponent
              leading="Ngày sinh"
              trailing={
                <DatePicker
                  locale={locale}
                  format="DD/MM/YYYY"
                  style={{ width: "300px" }}
                  placeholder="Chọn ngày sinh"
                  bordered="true"
                  selected={
                    formik.values.birthday
                      ? moment(formik.values.birthday)
                      : null
                  }
                  defaultValue={
                    formik.values.birthday
                      ? moment(formik.values.birthday)
                      : null
                  }
                  onChange={(date) => {
                    if (date) {
                      formik.setFieldValue("birthday", date.toDate(), true);
                    } else {
                      formik.setFieldValue("birthday", null);
                    }
                  }}
                />
              }
            />

            <RowLabelComponent
              leading="Địa chỉ"
              trailing={
                <TextInput
                  name="address"
                  placeholder="Địa chỉ liên hệ"
                  type="text"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                />
              }
            />
            <RowLabelComponent
              leading="Email"
              trailing={
                <TextInput
                  name="email"
                  placeholder="Địa chỉ email"
                  type="email"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              }
            />
            <RowLabelComponent
              leading="Số ĐT"
              trailing={
                <TextInput
                  name="phone"
                  placeholder="Số điện thoại liên hệ"
                  type="phone"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
              }
            />
            <RowLabelComponent
              leading="Tài khoản FPTS"
              trailing={
                <TextInput
                  name="stockId"
                  placeholder="FPTS account"
                  type="text"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.stockId}
                />
              }
            />

            <RowLabelComponent
              leading="Mã giới thiệu"
              trailing={
                <TextInput
                  name="userInvite"
                  placeholder="Mã code người giới thiệu"
                  type="text"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.userInvite}
                />
              }
            />

            <Divider light={true} />
            <RowLabelComponent
              leading="Loại tài khoản"
              trailing={
                <Select
                  name="memberType"
                  width={300}
                  maxWidth={300}
                  size="medium"
                  onChange={formik.handleChange}
                  value={formik.values.memberType}
                >
                  <option value="free">Member</option>
                  <option value="premium">Hạng Gold</option>
                  <option value="platium">Hạng Platium</option>
                </Select>
              }
            />

            {formik.values.memberType === "free" ? (
              <div />
            ) : (
              <RowLabelComponent
                leading="Thời hạn gói thuê bao"
                trailing={
                  <DatePicker
                    locale={locale}
                    format="DD/MM/YYYY"
                    placeholder="Chọn ngày hết hạn gói thuê bao"
                    bordered="true"
                    style={{ width: "300px" }}
                    disabledDate={(current) => {
                      return moment().add(-1, "days") >= current;
                    }}
                    selected={
                      formik.values.expireDate
                        ? moment(formik.values.expireDate)
                        : null
                    }
                    defaultValue={
                      formik.values.expireDate
                        ? moment(formik.values.expireDate)
                        : null
                    }
                    onChange={(date) => {
                      if (date) {
                        formik.setFieldValue("expireDate", date.toDate(), true);
                      } else {
                        formik.setFieldValue("expireDate", null);
                      }
                    }}
                  />
                }
              />
            )}
            <Divider light={true} />

            <Button
              type="submit"
              isLoading={submitting}
              appearance="primary"
              width={150}
              marginTop={majorScale(3)}
              marginRight={majorScale(3)}
            >
              Tạo mới
            </Button>
          </form>
        </Pane>
      )}
    </Pane>
  );
}

export default UserCreatePage;
