import { Route, Switch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { adminAtom } from "../../../data/admin_atoms";
import NoPermissionFrame from "../../components/NoPermission";
import RecommendDetailPage from "./detail";

function RecommendRouter() {
  const admin = useRecoilValue(adminAtom);
  return admin.role === "manager" ? (
    <NoPermissionFrame />
  ) : (
    <Switch>
      <Route
        key="/recommend"
        path="/recommend"
        component={RecommendDetailPage}
      />
    </Switch>
  );
}

export default RecommendRouter;
