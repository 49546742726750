import { Heading, Pane } from "evergreen-ui";
import React from "react";

function NoPermissionFrame() {
  return (
    <Pane
      display="flex"
      background="tint"
      margin="auto"
      height="50vh"
      width="50vw"
      minHeight="50vh"
      minWidth="50vw"
      alignItem="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Heading size={400} marginTop={100}>
        Bạn không có quyền truy cập nội dung này
      </Heading>
    </Pane>
  );
}

export default NoPermissionFrame;
