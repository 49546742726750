import { Route, Switch } from "react-router-dom";
import CategoriesPage from "./list";
import CategoryDetailPage from "./detail";
import CategoryCreatePage from "./create";

function CategoryRouter() {
  return (
    <Switch>
      <Route
        key="/category/list"
        path="/category/list"
        component={CategoriesPage}
      />
      <Route
        key="/category/detail/:id"
        path="/category/detail/:id"
        component={CategoryDetailPage}
      />
      <Route
        key="/category/create"
        path="/category/create"
        component={CategoryCreatePage}
      />
    </Switch>
  );
}

export default CategoryRouter;
