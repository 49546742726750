import {
  AddIcon,
  Button,
  Heading,
  Image,
  majorScale,
  minorScale,
  Pane,
  SearchInput,
  Table,
  Text,
  toaster,
} from "evergreen-ui";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory, useRouteMatch } from "react-router-dom";
import { formatDate, statusLabel } from "../../../helper/business_helper";
import { placeholder } from "../../../helper/image_helper";
import axiosClient from "../../../network/RestClient";
import LoadingFrame from "../../components/LoadingFrame";

function ServicesPage() {
  let { url } = useRouteMatch();
  const history = useHistory();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const fetchData = async function () {
    setLoading(true);
    try {
      const res = await axiosClient.get("/cms/service/search", {
        params: {
          search: search,
        },
      });
      if (res && res.status === 0) {
        setList((old) => [...res.data]);
      } else {
        toaster.danger(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      toaster.danger(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(5)}
      marginY={majorScale(3)}
      flexDirection="column"
    >
      <Pane
        display="flex"
        justifyContent="flex-start"
        alignItems="start"
        flexDirection="row"
      >
        <SearchInput
          placeholder="Tìm kiếm..."
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          marginRight={majorScale(2)}
          width={300}
        />
        <Button appearance="primary" onClick={(e) => fetchData()}>
          Tìm kiếm
        </Button>

        <Button
          appearance="primary"
          intent="danger"
          iconBefore={<AddIcon />}
          marginLeft="auto"
          onClick={(e) => history.push(url.replace("/list", "/create"))}
        >
          Tạo mới
        </Button>
      </Pane>
      <Table height="70vh" maxHeight="70vh" marginTop={majorScale(3)}>
        <Table.Head
          height={35}
          color="white"
          background="#0984e3"
          accountForScrollbar={false}
          borderLeft
          borderRight
        >
          <Table.TextHeaderCell flex={0.5}>#</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={5}>Thông tin</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={1}>Thứ tự App</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={4}>Link ĐK</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={1}>Trạng thái</Table.TextHeaderCell>
        </Table.Head>
        {loading ? (
          <LoadingFrame />
        ) : (
          <Table.Body>
            {list?.map((item, index) => (
              <Table.Row
                key={item._id}
                isSelectable
                borderLeft
                borderRight
                minHeight={40}
                maxHeight={120}
                paddingY={5}
                onSelect={() => {
                  history.push(
                    url.replace("/list", "/detail") + "/" + item._id
                  );
                }}
              >
                <Table.TextCell flex={0.5}>{index + 1}</Table.TextCell>
                <Table.TextCell flex={5}>
                  <Pane display="flex" flexDirection="row" alignItems="center">
                    <Image
                      src={item.image || placeholder}
                      style={{ objectFit: "cover" }}
                      maxWidth={80}
                      marginRight={majorScale(2)}
                    />
                    <Pane display="flex" flexDirection="column">
                      <Heading size={300} marginBottom={0}>
                        {item.name}
                      </Heading>
                    </Pane>
                  </Pane>
                </Table.TextCell>
                {/* <Table.TextCell flex={2}>
                  <NumberFormat
                    value={item.price}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix=" đ"
                    width={300}
                    customInput={Text}
                    renderText={(value, props) => <Text>{value}</Text>}
                  />
                </Table.TextCell> */}
                {/* <Table.TextCell flex={2}>
                  {`${item.duration} ngày`}
                </Table.TextCell> */}
                <Table.TextCell flex={1}>{item.order}</Table.TextCell>
                <Table.TextCell flex={4}>{item.registerLink}</Table.TextCell>
                <Table.TextCell flex={1}>
                  {statusLabel(item.status)}
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>
    </Pane>
  );
}

export default ServicesPage;
