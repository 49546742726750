import { Divider } from "@material-ui/core";
import {
  AddIcon,
  Button,
  FolderSharedIcon,
  majorScale,
  Pane,
  SegmentedControl,
  SideSheet,
  Spinner,
  Table,
  Text,
  Textarea,
  TextareaField,
  toaster,
} from "evergreen-ui";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { removeItemIndex, replaceItem } from "../../../helper/array_helper";
import { actionColor, actionLabel, formatDate } from "../../../helper/business_helper";
import { timeStamp2String } from "../../../helper/helper";
import axiosClient from "../../../network/RestClient";
import HeaderComponent from "../../components/HeaderComponent";
import LongTermDetail from "./form_long_term";
import ShortTermDetail from "./form_short_term";
import SpecialDetail from "./form_special_term";
import RowLabelComponent from "../../components/RowLabelComponent";

function RecommendDetailPage() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [model, setModel] = useState({});

  //dialog detail
  const [showShortTermDetail, setShowShortTermDetail] = useState(false);
  const [showLongTermDetail, setShowLongTermDetail] = useState(false);
  const [showSpecialDetail, setShowSpecialDetail] = useState(false);
  const [passData, setPassData] = useState(null);
  const [passIndex, setPassIndex] = useState(null);

  const [tabs] = useState([
    { label: "Ngắn hạn".toUpperCase(), value: "short" },
    { label: "Dài hạn".toUpperCase(), value: "long" },
    { label: "Đặc biệt".toUpperCase(), value: "special" },
  ]);
  const [tab, setTab] = useState("short");

  const fetchDetail = async function () {
    setLoading(true);
    try {
      const res = await axiosClient.get("/cms/recommend/detail", {});
      if (res && res.status === 0) {
        setModel(res.data);
      } else {
        toaster.danger(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      toaster.danger(err.message);
    }
    setLoading(false);
  };

  const update = async () => {
    setSubmitting(true);
    try {
      const res = await axiosClient.post("/cms/recommend/update", {
        note: formik.values.note || "",
        shortNote: formik.values.shortNote || "",
        longNote: formik.values.longNote || "",
        specialNote: formik.values.specialNote || "",
        shortTerm: formik.values.shortTerm || [],
        longTerm: formik.values.longTerm || [],
        special: formik.values.special || [],
      });
      if (res && res.status === 0) {
        toaster.success("Đã cập nhật thông tin");
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      note: model?.note || "",
      shortNote: model?.shortNote || "",
      longNote: model?.longNote || "",
      specialNote: model?.specialNote || "",
      shortTerm: model?.shortTerm || [],
      longTerm: model?.longTerm || [],
      special: model?.special || [],
    },
    onSubmit: (values) => {
      update();
    },
    validationSchema: Yup.object({}),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    fetchDetail();
    return () => {};
  }, []);

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(4)}
      marginY={majorScale(2)}
      flexDirection="column"
    >
      {loading ? (
        <Spinner />
      ) : (
        <Pane
          display="flex"
          marginY={majorScale(2)}
          paddingX={majorScale(4)}
          paddingY={majorScale(3)}
          flexDirection="column"
          background="white"
        >
          <SideSheet
            isShown={showShortTermDetail}
            onCloseComplete={() => setShowShortTermDetail(false)}
            width="55vw"
            preventBodyScrolling
            containerProps={{
              display: "flex",
              flex: "1",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ShortTermDetail
              onChange={(model) => {
                console.log(model);
                if (passData) {
                  const data = replaceItem(
                    formik.values.shortTerm,
                    passIndex,
                    model
                  );
                  formik.setFieldValue("shortTerm", [...data]);
                } else {
                  formik.setFieldValue("shortTerm", [
                    ...formik.values.shortTerm,
                    model,
                  ]);
                }
                setShowShortTermDetail(false);
              }}
              onRemove={() => {
                const data = removeItemIndex(
                  formik.values.shortTerm,
                  passIndex
                );
                formik.setFieldValue("shortTerm", [...data]);
              }}
              model={passData}
            />
          </SideSheet>

          <SideSheet
            isShown={showLongTermDetail}
            onCloseComplete={() => setShowLongTermDetail(false)}
            width="55vw"
            preventBodyScrolling
            containerProps={{
              display: "flex",
              flex: "1",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LongTermDetail
              onChange={(model) => {
                console.log(model);
                if (passData) {
                  const data = replaceItem(
                    formik.values.longTerm,
                    passIndex,
                    model
                  );
                  formik.setFieldValue("longTerm", [...data]);
                } else {
                  formik.setFieldValue("longTerm", [
                    ...formik.values.longTerm,
                    model,
                  ]);
                }
                setShowLongTermDetail(false);
              }}
              onRemove={() => {
                const data = removeItemIndex(formik.values.longTerm, passIndex);
                formik.setFieldValue("longTerm", [...data]);
              }}
              model={passData}
            />
          </SideSheet>

          <SideSheet
            isShown={showSpecialDetail}
            onCloseComplete={() => setShowSpecialDetail(false)}
            width="55vw"
            preventBodyScrolling
            containerProps={{
              display: "flex",
              flex: "1",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <SpecialDetail
              onChange={(model) => {
                console.log(model);
                if (passData) {
                  const data = replaceItem(
                    formik.values.special,
                    passIndex,
                    model
                  );
                  formik.setFieldValue("special", [...data]);
                } else {
                  formik.setFieldValue("special", [
                    ...formik.values.special,
                    model,
                  ]);
                }
                setShowSpecialDetail(false);
              }}
              onRemove={() => {
                const data = removeItemIndex(formik.values.special, passIndex);
                formik.setFieldValue("special", [...data]);
              }}
              model={passData}
            />
          </SideSheet>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            marginBottom={majorScale(2)}
          >
            <HeaderComponent>Khuyến nghị đầu tư</HeaderComponent>
          </Pane>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <Pane
              marginBottom={majorScale(2)}
              marginTop={majorScale(2)}
              display="flex"
              flexDirection="row"
            >
              <SegmentedControl
                width={300}
                options={tabs}
                value={tab}
                onChange={(value) => setTab(value)}
              />
              <Pane display="flex" flexDirection="row" marginLeft="auto">
                <Button
                  type="submit"
                  iconBefore={<FolderSharedIcon />}
                  isLoading={submitting}
                  appearance="primary"
                  paddingX={majorScale(3)}
                  marginRight={majorScale(2)}
                >
                  Lưu lại
                </Button>
                <Button
                  appearance="default"
                  type="button"
                  iconBefore={<AddIcon />}
                  onClick={(e) => {
                    setPassData(null);
                    setPassIndex(null);
                    if (tab === "short") {
                      setShowShortTermDetail(true);
                    } else if (tab === "long") {
                      setShowLongTermDetail(true);
                    } else {
                      setShowSpecialDetail(true);
                    }
                  }}
                >
                  Thêm
                </Button>
              </Pane>
            </Pane>

            {tab === "short" ? (
              <Pane display="flex" flexDirection="column">
                <Table
                  marginTop={majorScale(1)}
                  marginBottom={majorScale(3)}
                  accountForScrollbar={true}
                >
                  <Table.Head
                    height={30}
                    color="black"
                    accountForScrollbar={false}
                  >
                    <Table.TextHeaderCell flex={0.3}>#</Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>Mã CK</Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={2}>
                      Ngày KN
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>
                      Vùng giá KN
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>
                      Giá hiện tại
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>
                      Giá mục tiêu
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>
                      Sinh lời
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>
                      Trạng thái
                    </Table.TextHeaderCell>
                    {/* <Table.TextHeaderCell flex={0.5}>Xóa</Table.TextHeaderCell> */}
                  </Table.Head>
                  <Table.Body height={350}>
                    {formik.values.shortTerm?.map((item, index) => (
                      <Table.Row
                        key={index}
                        isSelectable
                        borderLeft
                        borderRight
                        height={45}
                        maxHeight={120}
                        paddingY={majorScale(1)}
                        onSelect={(e) => {
                          setPassData(item);
                          setPassIndex(index);
                          setShowShortTermDetail(true);
                        }}
                      >
                        <Table.TextCell flex={0.3}>{index + 1}</Table.TextCell>
                        <Table.TextCell flex={1}>
                          {item.stockCode}
                        </Table.TextCell>
                        <Table.TextCell flex={2}>
                          {formatDate(item.recommendDate, "DD/MM/yyyy")}
                        </Table.TextCell>
                        <Table.TextCell flex={1}>
                          {item.recommendPrice}
                        </Table.TextCell>
                        <Table.TextCell flex={1}>
                          {item.currentPrice}
                        </Table.TextCell>
                        <Table.TextCell flex={1}>
                          {item.targetPrice}
                        </Table.TextCell>
                        <Table.TextCell flex={1}>
                          {item.profitRate}
                        </Table.TextCell>
                        <Table.TextCell flex={1}>
                          <Text color={actionColor(item.action)} size={300}>
                            {actionLabel(item.action)}
                          </Text>
                        </Table.TextCell>
                        {/* <Table.TextCell flex={0.5}>
                        <IconButton
                          type="button"
                          icon={TrashIcon}
                          intent="danger"
                          size={50}
                          onClick={(e) => {
                            const data = removeItemIndex(
                              formik.values.shortTerm,
                              index
                            );
                            formik.setFieldValue("shortTerm", [...data]);
                          }}
                        />
                      </Table.TextCell> */}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <TextareaField
                  label="Ghi chú Ngắn hạn"
                  name="shortNote"
                  placeholder="Ghi chú ngắn hạn"
                  type="text"
                  inputHeight={150}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.shortNote}
                  validationMessage={formik.errors.shortNote != null}
                />
              </Pane>
            ) : tab === "long" ? (
              <Pane display="flex" flexDirection="column">
                <Table
                  marginTop={majorScale(1)}
                  marginBottom={majorScale(3)}
                  accountForScrollbar={true}
                >
                  <Table.Head
                    height={30}
                    color="black"
                    accountForScrollbar={false}
                  >
                    <Table.TextHeaderCell flex={0.3}>#</Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>Mã CK</Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={2}>
                      Nhóm ngành
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>
                      Vùng giá KN
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>
                      Giá hiện tại
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>
                      Định giá
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>
                      Sinh lời
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>
                      Trạng thái
                    </Table.TextHeaderCell>
                    {/* <Table.TextHeaderCell flex={0.5}>Xóa</Table.TextHeaderCell> */}
                  </Table.Head>
                  <Table.Body height={350}>
                    {formik.values.longTerm?.map((item, index) => (
                      <Table.Row
                        key={index}
                        isSelectable
                        borderLeft
                        borderRight
                        height={45}
                        maxHeight={120}
                        paddingY={majorScale(1)}
                        onSelect={(e) => {
                          setPassData(item);
                          setPassIndex(index);
                          setShowLongTermDetail(true);
                        }}
                      >
                        <Table.TextCell flex={0.3}>{index + 1}</Table.TextCell>
                        <Table.TextCell flex={1}>
                          {item.stockCode}
                        </Table.TextCell>
                        <Table.TextCell flex={2}>
                          {item.groupIndustry}
                        </Table.TextCell>
                        <Table.TextCell flex={1}>
                          {item.recommendPrice}
                        </Table.TextCell>
                        <Table.TextCell flex={1}>
                          {item.currentPrice}
                        </Table.TextCell>
                        <Table.TextCell flex={1}>
                          {item.targetPrice}
                        </Table.TextCell>
                        <Table.TextCell flex={1}>
                          {item.profitRate}
                        </Table.TextCell>
                        <Table.TextCell flex={1}>
                          <Text color={actionColor(item.action)} size={300}>
                            {actionLabel(item.action)}
                          </Text>
                        </Table.TextCell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <TextareaField
                  label="Ghi chú Dài hạn"
                  name="longNote"
                  placeholder="Ghi chú dài hạn"
                  type="text"
                  inputHeight={150}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.longNote}
                  validationMessage={formik.errors.longNote != null}
                />
              </Pane>
            ) : (
              <Pane display="flex" flexDirection="column">
                <Table
                  marginTop={majorScale(1)}
                  marginBottom={majorScale(3)}
                  accountForScrollbar={true}
                >
                  <Table.Head
                    height={30}
                    color="black"
                    accountForScrollbar={false}
                  >
                    <Table.TextHeaderCell flex={0.3}>#</Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>Mã CK</Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={2}>
                      Ngày KN
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>
                      Vùng giá KN
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>
                      Giá hiện tại
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>
                      Giá mục tiêu
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>
                      Sinh lời
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={1}>
                      Trạng thái
                    </Table.TextHeaderCell>
                  </Table.Head>
                  <Table.Body height={350}>
                    {formik.values.special?.map((item, index) => (
                      <Table.Row
                        key={index}
                        isSelectable
                        borderLeft
                        borderRight
                        height={45}
                        maxHeight={120}
                        paddingY={majorScale(1)}
                        onSelect={(e) => {
                          setPassData(item);
                          setPassIndex(index);
                          setShowSpecialDetail(true);
                        }}
                      >
                        <Table.TextCell flex={0.3}>{index + 1}</Table.TextCell>
                        <Table.TextCell flex={1}>
                          {item.stockCode}
                        </Table.TextCell>
                        <Table.TextCell flex={2}>
                          {formatDate(item.recommendDate, "DD/MM/yyyy")}
                        </Table.TextCell>
                        <Table.TextCell flex={1}>
                          {item.recommendPrice}
                        </Table.TextCell>
                        <Table.TextCell flex={1}>
                          {item.currentPrice}
                        </Table.TextCell>
                        <Table.TextCell flex={1}>
                          {item.targetPrice}
                        </Table.TextCell>
                        <Table.TextCell flex={1}>
                          {item.profitRate}
                        </Table.TextCell>
                        <Table.TextCell flex={1}>
                          <Text color={actionColor(item.action)} size={300}>
                            {actionLabel(item.action)}
                          </Text>
                        </Table.TextCell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <TextareaField
                  label="Ghi chú Đặc biệt"
                  name="specialNote"
                  placeholder="Ghi chú Đặc biệt"
                  type="text"
                  inputHeight={150}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.specialNote}
                  validationMessage={formik.errors.specialNote != null}
                />
              </Pane>
            )}
          </form>
        </Pane>
      )}
    </Pane>
  );
}

export default RecommendDetailPage;
