import {
  Button,
  Heading,
  minorScale,
  Pane,
  Strong,
  TextInput,
  toaster,
} from "evergreen-ui";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axiosClient, { udpateAxiosToken } from "../../../network/RestClient";

function LoginForm(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // Click đăng nhập
  const login = async function (e) {
    e.preventDefault();
    setLoading(true);
    try {
      const rs = await axiosClient.post("/cms/login", {
        username: email,
        password: password,
      });
      if (rs && rs.status === 0) {
        toaster.success("Đăng nhập thành công");
        udpateAxiosToken(rs.data.token);
        history.push("/");
      } else {
        toaster.danger(rs?.message ?? rs?.data ?? "Đăng nhập thất bại");
      }
    } catch (err) {
      toaster.danger(err.message);
    } 
    setLoading(false);
  };
  return (
    <Pane
      elevation={4}
      float="left"
      paddingY={40}
      paddingX={80}
      margin="auto"
      display="flex"
      background="white"
      borderRadius={8}
      justifyContent="center"
      alignItems="left"
      flexDirection="column"
    >
      <Heading size={600}>Đăng nhập tài khoản</Heading>
      <Strong size={400} marginTop={minorScale(5)}>
        Tên đăng nhập
      </Strong>
      <TextInput
        type="text"
        name="username"
        autoComplete="false"
        placeholder="Nhập tài khoản"
        marginTop={minorScale(2)}
        onChange={(e) => setEmail(e.currentTarget.value)}
      />
      <Strong size={400} marginTop={minorScale(3)}>
        Mật khẩu
      </Strong>
      <TextInput
        name="password"
        autoComplete="false"
        placeholder="Nhập mật khẩu..."
        marginTop={minorScale(2)}
        type="password"
        onChange={(e) => setPassword(e.currentTarget.value)}
      />
      <Button
        appearance="primary"
        size="medium"
        marginTop={minorScale(7)}
        isLoading={loading}
        onClick={login}
      >
        Đăng nhập
      </Button>
    </Pane>
  );
}

export default LoginForm;
