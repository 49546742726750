import { Heading } from "evergreen-ui";

function TagComponent(props) {
  return (
    <Heading size={200} marginRight={3} color="gray">
      {"#" + props.children.toUpperCase()}
    </Heading>
  );
}

export default TagComponent;
