import { Route, Switch } from "react-router-dom";
import CategoryRouter from "./category/route";
import NewsRouter from "./news/route";
import RecommendRouter from "./recommend/route";

function NewsAllRouter() {
  return (
    <Switch>
      <Route key="/news/" path="/news/" component={NewsRouter} />
      <Route key="/category" path="/category" component={CategoryRouter} />
      <Route key="/recommend" path="/recommend" component={RecommendRouter} />
    </Switch>
  );
}

export default NewsAllRouter;
