import {
  Avatar, Heading,
  LogOutIcon,
  Menu,
  minorScale,
  Pane,
  Popover,
  Position, Text,
  UserIcon
} from "evergreen-ui";
import React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { adminAtom } from "../../../data/admin_atoms";
import { clearAxiosToken } from "../../../network/RestClient";

function Header(props) {
  const history = useHistory();
  const [admin, setAdmin] = useRecoilState(adminAtom);

  const showUserMenu = function (e) {
    e.preventDefault();
  };

  const logout = function (e) {
    e.preventDefault();
    setAdmin({});
    clearAxiosToken();
    history.replace("/login");
  };

  return (
    <Pane
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="default"
     
      paddingX={minorScale(3)}
      paddingY={minorScale(4)}
      background="white"
      height={70}
    >
      
      <Pane
        display="flex"
        flexDirection="row"
        alignContent="center"
        alignItems="center"
        marginLeft="auto"
      >
        <Popover
          position={Position.BOTTOM_LEFT}
          content={
            <Menu>
              <Menu.Group>
                <Menu.Item icon={UserIcon}>
                  <Heading size={400}>
                    {admin
                      ? admin.displayName?.toUpperCase()
                      : "Chưa có thông tin"}
                  </Heading>
                  <Text size={300}>
                    {admin ? admin.username : "Chưa có thông tin"}
                  </Text>
                </Menu.Item>
              </Menu.Group>
              <Menu.Divider />
              <Menu.Group>
                <Menu.Item icon={LogOutIcon} intent="danger" onSelect={logout}>
                  Đăng xuất
                </Menu.Item>
              </Menu.Group>
            </Menu>
          }
        >
          <Avatar
            name={admin ? admin.displayName : "Chưa có thông tin"}
            size={40}
            marginRight={minorScale(4)}
            onClick={showUserMenu}
          />
        </Popover>
      </Pane>
    </Pane>
  );
}

export default Header;
