import { Pane, toaster } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { adminAtom } from "../../../data/admin_atoms";
import axiosClient from "../../../network/RestClient";
import SplashPage from "../../splash/SplashPage";
import { tabs } from "../../tabs";
import Header from "../components/Header";
import SlideMenu from "../components/SlideMenu";

function HomePage(props) {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("");
  const setAdminAtom = useSetRecoilState(adminAtom);

  const fetchProfile = async function () {
    const goToLogin = () => {
      history.replace("/login");
    };
    setLoading(true);
    try {
      const token = localStorage.getItem("auth_token");
      if (token) {
        const rs = await axiosClient.get("/cms/profile", {});
        if (rs && rs.status === 0) {
          setLoading(false);
          setAdminAtom((old) => rs.data);
        } else {
          if (rs.statusCode === 463) {
            toaster.danger(
              "Phiên đăng nhập cũ đã hết hạn. Vui lòng đăng nhập lại!"
            );
          } else {
            toaster.danger(rs?.message ?? "Yêu cầu đăng nhập");
          }
          goToLogin();
        }
      } else {
        goToLogin();
      }
    } catch (err) {
      console.log(err);
      goToLogin();
    }
  };

  useEffect(() => {
    tabs.forEach((el, index) => {
      if (el.children) {
        el.children.forEach((child, index) => {
          if (location.pathname.startsWith(child.path)) {
            setSelectedTab(child.path);
          }
        });
      } else {
        if (location.pathname.startsWith(el.path)) {
          setSelectedTab(el.path);
        }
      }
    });
    return () => {};
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      fetchProfile();
    }, 100);
    return () => {};
  }, []);

  return loading ? (
    <SplashPage />
  ) : (
    <Pane
      display="flex"
      flexDirection="row"
      margin={0}
      padding={0}
      minHeight="100vh"
      maxHeight="100vh"
      background="black"
    >
      <SlideMenu
        tabs={tabs}
        selectedTab={selectedTab}
        onSelect={(key) => {
          history.push(key);
        }}
      />
      <Pane
        background="gray200"
        flex="1"
        flexDirection="column"
        margin={0}
        padding={0}
        minHeight="100vh"
        maxHeight="100vh"
        style={{
          overflowY: "scroll",
        }}
      >
        <Header />
        <Pane>
          <Switch>
            {tabs.map((tab, index) => (
              <Route key={tab.path} path={tab.path} component={tab.page} />
            ))}
          </Switch>
        </Pane>
      </Pane>
    </Pane>
  );
}

export default HomePage;
