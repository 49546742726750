import { Pane, Spinner } from "evergreen-ui";
import React from "react";

function LoadingFrame() {
  return (
    <Pane
      display="flex"
      background="tint"
      margin="auto"
      height="50vh"
      width="50vw"
    >
      <Spinner />
    </Pane>
  );
}

export default LoadingFrame;
