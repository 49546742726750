import { Pane, Image, Text } from "evergreen-ui";
import React from "react";
import bg from "../../assets/image/bg_404.png";

function Page404(props) {
  return (
    <Pane
      display="flex"
      flex={1}
      height="90vh"
      width="90vw"
      margin="auto"
      background="white"
      justifyContent="center"
      alignContent="center"
      flexDirection="column"
    >
      <Pane
        display="flex"
        flex={1}
        margin="auto"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Image src={bg} width={400} />
        <Text size={600} marginTop={30}>
          Không tìm thấy trang bạn muốn truy cập
        </Text>
      </Pane>
    </Pane>
  );
}

export default Page404;
