import { Menu } from "antd";
import "antd/dist/antd.css";
import { Image, majorScale, Pane, Paragraph } from "evergreen-ui";
import React from "react";
import logo from "../../../assets/image/logo192.png";

function SlideMenu(props) {
  const { SubMenu } = Menu;
  const selected = [props.selectedTab];

  const selectedSub = [];
  props.tabs.forEach((tab) => {
    if (tab.children && props.selectedTab.startsWith(tab.path)) {
      selectedSub.push(tab.path);
    }
  });

  return (
    <Pane
      display="flex"
      flexDirection="column"
      borderRight="muted"
      background="#001529"
      justifyContent="flex-start"
      margin={0}
      padding={0}
      minHeight="100vh"
      maxHeight="100vh"
    >
      <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={110}
      >
        <Image
          src={logo}
          width={100}
          cursor="pointer"
          onClick={(e) => props.onSelect("/home")}
        />
      </Pane>
      <Pane
        display="flex"
        flex={1}
        background="#001529"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Menu
          style={{ width: 230, maxHeight: "100vh", overflow: "scroll" }}
          mode="inline"
          theme="dark"
          selectedKeys={selected}
          defaultOpenKeys={selectedSub}
          // inlineCollapsed="true"
          onClick={(e) => props.onSelect(e.key)}
        >
          {props.tabs.map((tab, index) =>
            tab.children ? (
              <SubMenu key={tab.path + "s"} icon={tab.icon} title={tab.title}>
                {tab.children.map((child, index) => (
                  <Menu.Item key={child.path} icon={child.icon}>
                    {child.title}
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={tab.path} icon={tab.icon}>
                {tab.title}
              </Menu.Item>
            )
          )}
        </Menu>

        <Paragraph size={300} margin={majorScale(2)}>
          © 2021 HNK Stock
        </Paragraph>
      </Pane>
    </Pane>
  );
}

export default SlideMenu;
