import { Route, Switch } from "react-router-dom";
import GroupDetailPage from "./detail";
import GroupsPage from "./list";
import GroupCreatePage from "./create";
import NoPermissionFrame from "../../components/NoPermission";
import { useRecoilValue } from "recoil";
import { adminAtom } from "../../../data/admin_atoms";


function GroupRouter() {
  const admin = useRecoilValue(adminAtom);
  return admin.role === "manager" ? (
    <NoPermissionFrame />
  ) : (
    <Switch>
      <Route key="/group/list" path="/group/list" component={GroupsPage} />
      <Route
        key="/group/detail/:id"
        path="/group/detail/:id"
        component={GroupDetailPage}
      />
      <Route
        key="/group/create"
        path="/group/create"
        component={GroupCreatePage}
      />
  
    </Switch>
  );
}

export default GroupRouter;
