import {
  majorScale,
  Pane,
  Strong,
  Table,
  Text,
  toaster,
  Tooltip,
} from "evergreen-ui";
import { useEffect, useState } from "react";
import { formatDate } from "../../../helper/business_helper";
import axiosClient from "../../../network/RestClient";
import LoadingFrame from "../../components/LoadingFrame";

function IapLogsPage() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  // phan trang
  const [page, setPage] = useState(1);
  const [totalResult, setTotalResult] = useState(1);
  const limit = 10;

  const fetchData = async function () {
    setLoading(true);
    try {
      const res = await axiosClient.get("/cms/iaplog/search", {
        params: {
          search: search,
          limit: limit,
          offset: limit * (page - 1),
        },
      });
      if (res && res.status === 0) {
        setList((old) => [...res.data]);
      } else {
        toaster.danger(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      toaster.danger(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, [page]);

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(5)}
      marginY={majorScale(3)}
      flexDirection="column"
    >
      <Pane
        display="flex"
        justifyContent="flex-start"
        alignItems="start"
        flexDirection="row"
        marginTop={majorScale(2)}
      >
        <Pane
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
          marginRight={majorScale(2)}
        >
          {/* <SelectMenu
            title="Trạng thái"
            options={[
              {
                label: "Ngày cập nhật",
                value: "updatedAt",
              },
              {
                label: "Ngày hết hạn",
                value: "expiredTime",
              },
            ]}
            selected={sort}
            hasFilter={false}
            hasTitle={true}
            onSelect={(item) => setSort(item.value)}
          >
            <Button iconBefore={SortIcon}>Sắp xếp</Button>
          </SelectMenu> */}
        </Pane>
      </Pane>
      <Table marginTop={majorScale(2)}>
        <Table.Head
          height={35}
          color="white"
          background="#0984e3"
          accountForScrollbar={false}
          borderLeft
          borderRight
        >
          <Table.TextHeaderCell flex={0.3}>#</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={4}>Thông tin</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={2}>Mã tham chiếu</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={1}>Gói mua</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={2}>Ngày thanh toán</Table.TextHeaderCell>
        </Table.Head>
        {loading ? (
          <LoadingFrame />
        ) : (
          <Table.Body height="65vh" maxHeight="65vh">
            {list?.map((item, index) => (
              <Table.Row
                key={item._id}
                isSelectable
                borderLeft
                borderRight
                height={70}
                maxHeight={120}
                paddingY={majorScale(1)}
              >
                <Table.TextCell flex={0.3}>
                  {index + 1 + (page - 1) * limit}
                </Table.TextCell>
                <Table.TextCell flex={4}>
                  <Pane display="flex" flexDirection="column">
                    <Text>
                      <Strong>{item?.userRef?.fullName}</Strong> -{" "}
                      <Strong>{item?.userRef?.username}</Strong>
                    </Text>
                    <Text>ID : {item?.userRef?._id}</Text>
                  </Pane>
                </Table.TextCell>
                <Table.TextCell flex={2}>
                  <Strong>#{item.transId}</Strong>
                </Table.TextCell>
                <Table.TextCell flex={1}>
                  <Strong>{item.productId}</Strong>
                </Table.TextCell>
                <Table.TextCell flex={2}>
                  <Text>{formatDate(item.purchaseDate)}</Text>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>
      {/* <Pagination
        current={page}
        pageSize={limit}
        total={totalResult}
        alignSelf="end"
        marginTop={majorScale(1)}
        onChange={(value) => {
          setPage(value);
        }}
      /> */}
    </Pane>
  );
}

export default IapLogsPage;
