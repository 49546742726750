import { Pagination } from "antd";
import {
  AddIcon,
  Avatar,
  Button,
  Heading,
  IconButton,
  majorScale,
  Pane,
  SearchInput,
  Table,
  Text,
  toaster,
} from "evergreen-ui";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { statusLabel } from "../../../helper/business_helper";
import axiosClient from "../../../network/RestClient";
import LoadingFrame from "../../components/LoadingFrame";

function UsersSelectorPage(props) {
  let { url } = useRouteMatch();
  const history = useHistory();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  // phan trang
  const [page, setPage] = useState(1);
  const [totalResult, setTotalResult] = useState(1);
  const limit = 10;

  const fetchData = async function () {
    setLoading(true);
    try {
      const res = await axiosClient.get("/cms/user/search", {
        params: {
          search: search,
          limit: limit,
          offset: limit * (page - 1),
        },
      });
      if (res && res.status === 0) {
        setList((old) => [...res.data]);
        setTotalResult(res.totalResult);
      } else {
        toaster.danger(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      toaster.danger(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, [page]);

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(5)}
      marginY={majorScale(3)}
      flexDirection="column"
    >
      <Pane
        display="flex"
        justifyContent="flex-start"
        alignItems="start"
        flexDirection="row"
      >
        <SearchInput
          placeholder="Tìm kiếm..."
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          marginRight={majorScale(2)}
          width={300}
        />
        <Button type="button" appearance="primary" onClick={(e) => fetchData()}>
          Tìm kiếm
        </Button>
      </Pane>
      <Table marginTop={majorScale(3)}>
        <Table.Head
          height={30}
          color="white"
          background="#0984e3"
          accountForScrollbar={false}
          borderLeft
          borderRight
        >
          <Table.TextHeaderCell flex={0.5}>#</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={5}>Thông tin</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={2}>Trạng thái</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={2}>Chọn</Table.TextHeaderCell>
        </Table.Head>
        {loading ? (
          <LoadingFrame />
        ) : (
          <Table.Body height="500" maxHeight="500">
            {list?.map((item, index) => (
              <Table.Row
                key={item._id}
                isSelectable
                borderLeft
                borderRight
                height={50}
                maxHeight={120}
                paddingY={majorScale(1)}
              >
                <Table.TextCell flex={0.5}>{index + 1}</Table.TextCell>
                <Table.TextCell flex={5}>
                  <Pane display="flex" flexDirection="row" alignItems="center">
                    <Avatar
                      size={30}
                      src={item.avatar}
                      name={item.fullName}
                      marginRight={majorScale(2)}
                    />
                    <Pane display="flex" flexDirection="column">
                      <Heading size={400}>{item.fullName}</Heading>
                      <Text size={300}>{item.username}</Text>
                    </Pane>
                  </Pane>
                </Table.TextCell>
                <Table.TextCell flex={2}>
                  {statusLabel(item.status)}
                </Table.TextCell>
                <Table.TextCell flex={2}>
                  <IconButton
                    icon={AddIcon}
                    intent="success"
                    onClick={(e) => props.onAdd(item)}
                  >
                    Thêm
                  </IconButton>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>
      <Pagination
        current={page}
        pageSize={limit}
        total={totalResult}
        // showTotal={true}
        alignSelf="end"
        marginTop={majorScale(1)}
        onChange={(value) => {
          setPage(value);
        }}
      />
    </Pane>
  );
}

export default UsersSelectorPage;
