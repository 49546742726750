export const removeItemIndex = (arr, index) => {
  console.log("1 old arr");
  console.log(arr);
  arr.splice(index, 1);
  console.log("1 new arr");
  console.log(arr);
  return arr;
};

export const replaceItem = (arr, index, item) => {
  console.log("old arr");
  console.log(arr);
  arr.splice(index, 1, item);
  console.log("new arr");
  console.log(arr);
  return arr;
};
