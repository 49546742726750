import { Pane } from "evergreen-ui";
import Lottie from "lottie-react";
import React from "react";
import splashLottie from "../../assets/lottie/mushroom_jumping.json";

function SplashPage(props) {
  return (
    <Pane
      display="flex"
      background="tint"
      margin="auto"
      height="90vh"
      padding={0}
    >
      <Pane display="flex" width="500" height="500" margin="auto">
        <Lottie animationData={splashLottie} />
      </Pane>
    </Pane>
  );
}

export default SplashPage;
