import { Divider } from "@material-ui/core";
import {
  Button,
  CornerDialog,
  majorScale,
  Pane,
  Select,
  Spinner,
  TextInput,
  toaster,
  TrashIcon,
} from "evergreen-ui";
import { useFormik } from "formik";
import ImageUpload from "image-upload-react";
import "image-upload-react/dist/index.css";
import JoditEditor from "jodit-react";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import axiosClient from "../../../network/RestClient";
import HeaderComponent from "../../components/HeaderComponent";
import RowLabelComponent from "../../components/RowLabelComponent";

function ServiceDetailPage() {
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [model, setModel] = useState({});

  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const [config, setConfig] = useState({
    readonly: false,
    toolbar: true,
    minHeight: 550,
    maxHeight: 1500,
    maxWidth: "70vw",
    style: {
      background: "white",
    },
  });

  useEffect(() => {
    if (!image) setImageFile(null);
  }, [image]);

  useEffect(() => {
    console.log(imageFile);
  }, [imageFile]);

  // dialog xóa
  const [dialogDeleteShow, setDialogDeleteShow] = useState(false);

  const fetchDetail = async function () {
    setLoading(true);
    try {
      const res = await axiosClient.get("/cms/service/detail", {
        params: {
          id: id,
        },
      });
      if (res && res.status === 0) {
        setModel(res.data);
        setImage(res.data?.image);
      } else {
        toaster.danger(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      toaster.danger(err.message);
    }
    setLoading(false);
  };

  const update = async () => {
    setSubmitting(true);
    try {
      let imageLink = "";
      if (imageFile) {
        const formData = new FormData();
        formData.append("file", imageFile);
        formData.append("fileName", `service_${id}`);
        const res1 = await axiosClient.post(
          "/cms/media/upload-image2",
          formData,
          {}
        );
        if (res1.status === 0) {
          imageLink = res1.data;
          console.log(`imagelink = ${imageLink}`);
        }
      } else {
        imageLink = model?.image;
      }
      const res = await axiosClient.post("/cms/service/update", {
        id: id,
        name: formik.values.name || "",
        description: formik.values.description || "",
        registerLink: formik.values.registerLink || "",
        image: imageLink,
        duration: formik.values.duration,
        price: formik.values.price,
        order: formik.values.order,
        status: formik.values.status || "active",
      });
      if (res && res.status === 0) {
        toaster.success("Đã cập nhật thông tin");
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const remove = async () => {
    setSubmitting(true);
    try {
      const res = await axiosClient.get("/cms/service/delete", {
        params: {
          id: id,
        },
      });
      if (res && res.status === 0) {
        toaster.success("Đã xóa");
        history.push("/service/list");
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      name: model?.name || "",
      description: model?.description || "",
      image: model?.image,
      registerLink: model?.registerLink,
      duration: model?.duration,
      price: model?.price,
      order: model?.order,
      status: model?.status || "active",
    },
    onSubmit: (values) => {
      update();
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Bắt buộc nhập tiêu đề"),
      // description: Yup.string().required("Bắt buộc nhập mô tả"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    fetchDetail();
    return () => {};
  }, [id]);

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(4)}
      marginY={majorScale(2)}
      flexDirection="column"
    >
      {loading ? (
        <Spinner />
      ) : (
        <Pane
          display="flex"
          marginY={majorScale(2)}
          paddingX={majorScale(4)}
          paddingY={majorScale(3)}
          flexDirection="column"
          background="white"
        >
          <CornerDialog
            title="Xóa bản ghi?"
            intent="danger"
            isShown={dialogDeleteShow}
            cancelLabel="Hủy"
            confirmLabel="Có xóa"
            onCloseComplete={() => setDialogDeleteShow(false)}
            onConfirm={() => {
              setDialogDeleteShow(false);
              remove();
            }}
          >
            Bạn có chắc chắn muốn xóa bản ghi này?
          </CornerDialog>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            marginBottom={majorScale(2)}
          >
            <HeaderComponent>Thông tin Sản phẩm</HeaderComponent>
          </Pane>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <RowLabelComponent
              leading="Ảnh đại diện"
              trailing={
                <ImageUpload
                  handleImageSelect={(e) => {
                    setImageFile(e.target.files[0]);
                    const url = URL.createObjectURL(e.target.files[0]);
                    setImage(url);
                  }}
                  defaultDeleteIconSize={50}
                  defaultDeleteIconColor="#222222"
                  deleteIcon={<div color="white">🗑️</div>}
                  imageSrc={image}
                  setImageSrc={setImage}
                  style={{
                    margin: 0,
                    width: 160,
                    height: 100,
                    background: "grey",
                    objectFit: "cover",
                  }}
                />
              }
            />
            <RowLabelComponent
              leading="Tiêu đề"
              trailing={
                <TextInput
                  name="name"
                  placeholder="Nhập tiêu đề dịch vụ"
                  type="text"
                  width={600}
                  maxWidth={600}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  isInvalid={formik.errors.name != null}
                />
              }
            />
            <RowLabelComponent
              leading="Mô tả"
              alignItems="flex-start"
              trailing={
                <JoditEditor
                  config={config}
                  onChange={(value) =>
                    formik.setFieldValue("description", value)
                  }
                  value={formik.values.description}
                />
              }
            />
            <RowLabelComponent
              leading="Link đăng ký"
              trailing={
                <TextInput
                  name="registerLink"
                  placeholder=""
                  type="text"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.registerLink}
                />
              }
            />
            {/* <RowLabelComponent
              leading="Chu kì (ngày)"
              trailing={
                <TextInput
                  name="duration"
                  placeholder="VD: 300 ngày.."
                  type="number"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.duration}
                />
              }
            />
            <RowLabelComponent
              leading="Giá gói"
              trailing={
                <NumberFormat
                  value={formik.values.price}
                  displayType={"number"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" đ"
                  width={300}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    formik.setFieldValue("price", value);
                  }}
                  customInput={TextInput}
                />
              }
            /> */}
            <RowLabelComponent
              leading="Thứ tự trên mobile"
              trailing={
                <TextInput
                  name="order"
                  placeholder="0"
                  type="number"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.order}
                />
              }
            />
            <RowLabelComponent
              leading="Trạng thái"
              trailing={
                <Select
                  name="status"
                  width={300}
                  maxWidth={300}
                  size="medium"
                  onChange={formik.handleChange}
                  value={formik.values.status}
                >
                  <option value="active">Hoạt động</option>
                  <option value="block">Bị khóa</option>
                </Select>
              }
            />

            <Divider light={true} />
            <Button
              type="submit"
              isLoading={submitting}
              appearance="primary"
              width={150}
              marginTop={majorScale(3)}
              marginRight={majorScale(3)}
            >
              Cập nhật
            </Button>

            <Button
              type="button"
              intent="danger"
              isLoading={submitting}
              appearance="primary"
              iconBefore={TrashIcon}
              width={150}
              marginTop={majorScale(3)}
              onClick={(e) => setDialogDeleteShow(true)}
            >
              Xóa
            </Button>
          </form>
        </Pane>
      )}
    </Pane>
  );
}

export default ServiceDetailPage;
