import { Divider } from "@material-ui/core";
import {
	majorScale,
	Pane, Spinner
} from "evergreen-ui";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import HeaderComponent from "../components/HeaderComponent";

function DashboardPage() {
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState({});

  const fetchDetail = async function () {
    setLoading(true);
    setLoading(false);
  };

  useEffect(() => {
    fetchDetail();
    return () => {};
  }, []);

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(4)}
      marginY={majorScale(2)}
      minHeight="90vh"
      flexDirection="column"
    >
      {loading ? (
        <Spinner />
      ) : (
        <Pane
          display="flex"
          marginY={majorScale(2)}
          paddingX={majorScale(4)}
          paddingY={majorScale(3)}
          flexDirection="column"
          flex={1}
          background="white"
        >
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            marginBottom={majorScale(2)}
          >
            <HeaderComponent>Tổng quan</HeaderComponent>
          </Pane>
          <Divider />
         
        </Pane>
      )}
    </Pane>
  );
}

export default DashboardPage;
