import { Image, Pane } from "evergreen-ui";
import bg from "../../../assets/image/bg_login.jpg";
import LoginForm from "../components/LoginForm.js";
import "./LoginPage.css";

function LoginPage(props) {
  return (
    <Pane
      display="flex"
      background="neutral"
      margin={0}
      padding={0}
      height="100vh"
    >
      <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex={1}
        background="tint"
        flexDirection="column"
        height="100vh"
      >
        <LoginForm />
      </Pane>
      <Pane flex={1} height="100vh">
        <Image
          src={bg}
          style={{ objectFit: "cover" }}
          alt=""
          height="100vh"
          width="100%"
        />
      </Pane>
    </Pane>
  );
}

export default LoginPage;
