import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { RecoilRoot } from "recoil";
import HomePage from "./home/pages/HomePage";
import LoginPage from "./login/pages/LoginPage";
import Page404 from "./p404/Page404";

function App() {
  return (
    <RecoilRoot>
      <Router>
        <Switch>
          {/* <Route exact path="/" component={SplashPage} /> */}
          <Route path="/login" component={LoginPage} />
          <Route path="/" component={HomePage} />
          <Route path="/*" component={Page404} />
        </Switch>
      </Router>
    </RecoilRoot>
  );
}

export default App;
