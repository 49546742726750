import { faAppleAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AddIcon,
  ClipboardIcon,
  DashboardIcon,
  DocumentIcon,
  DocumentShareIcon,
  DollarIcon,
  IpAddressIcon, PeopleIcon,
  UserIcon
} from "evergreen-ui";
import ContactPage from "./chucnang/contact/contact_page";
import GroupRouter from "./chucnang/group/route";
import IapLogsPage from "./chucnang/log/iap";
import NewsAllRouter from "./chucnang/news_all_route";
import PushNotificationPage from "./chucnang/push_content/push_page";
import RecommendRouter from "./chucnang/recommend/route";
import ServiceRouter from "./chucnang/service/route";
import UserRouter from "./chucnang/user/route";
import DashboardPage from "./dashboard/detail";

export const tabs = [
  {
    title: "Tổng quan",
    icon: <DashboardIcon />,
    path: "/home",
    exact: true,
    page: () => <DashboardPage />,
    children: null,
  },
  {
    title: "QL Người dùng",
    icon: <UserIcon />,
    path: "/user",
    page: () => <UserRouter />,
    children: [
      {
        title: "Danh sách",
        icon: <UserIcon />,
        path: "/user/list",
      },
      {
        title: "Tạo mới",
        icon: <AddIcon />,
        path: "/user/create",
      },
    ],
  },
  {
    title: "QL Nhóm",
    icon: <PeopleIcon />,
    path: "/group",
    page: () => <GroupRouter />,
    children: [
      {
        title: "Danh sách",
        icon: <PeopleIcon />,
        path: "/group/list",
      },
      {
        title: "Tạo mới",
        icon: <AddIcon />,
        path: "/group/create",
      },
    ],
  },
  {
    title: "QL Bài viết",
    icon: <DocumentIcon />,
    path: ["/news", "/category", "/recommend"],
    page: () => <NewsAllRouter />,
    children: [
      {
        title: "Bài viết",
        icon: <DocumentIcon />,
        path: "/news/list",
      },
      {
        title: "Tạo bài viết",
        icon: <AddIcon />,
        path: "/news/create",
      },
      {
        title: "Chuyên mục",
        icon: <ClipboardIcon />,
        path: "/category/list",
      },
      {
        title: "Tạo Chuyên mục",
        icon: <AddIcon />,
        path: "/category/create",
      },
    ],
  },
  {
    title: "Khuyến nghị đầu tư",
    icon: <DollarIcon />,
    path: "/recommend",
    page: () => <RecommendRouter />,
    children: null,
  },
  {
    title: "Push bài viết",
    icon: <DocumentShareIcon />,
    path: "/push",
    page: () => <PushNotificationPage />,
    children: null,
  },
  {
    title: "Sản phẩm & dịch vụ",
    icon: <AddIcon />,
    path: "/service",
    page: () => <ServiceRouter />,
    children: [
      {
        title: "Danh sách",
        icon: <DocumentIcon />,
        path: "/service/list",
      },
      {
        title: "Tạo mới",
        icon: <AddIcon />,
        path: "/service/create",
      },
    ],
  },
  {
    title: "Log thanh toán Apple",
    icon: <FontAwesomeIcon icon={faAppleAlt} />,
    path: "/iaplog",
    page: () => <IapLogsPage />,
    children: null,
  },
  {
    title: "Liên hệ & Thanh toán",
    icon: <IpAddressIcon />,
    path: "/contact",
    page: () => <ContactPage />,
    children: null,
  },
];
