import { Divider } from "@material-ui/core";
import {
  Button,
  majorScale,
  Pane,
  Spinner,
  Textarea,
  TextInput,
  toaster,
} from "evergreen-ui";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import axiosClient from "../../../network/RestClient";
import HeaderComponent from "../../components/HeaderComponent";
import RowLabelComponent from "../../components/RowLabelComponent";
import NumberFormat from "react-number-format";
import ImageUpload from "image-upload-react";
import "image-upload-react/dist/index.css";
import JoditEditor from "jodit-react";

function ServiceCreatePage() {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const [config, setConfig] = useState({
    readonly: false,
    toolbar: true,
    minHeight: 350,
    maxHeight: 1500,
    maxWidth: "70vw",
    style: {
      background: "white",
    },
  });

  useEffect(() => {
    if (!image) setImageFile(null);
  }, [image]);

  const create = async () => {
    setSubmitting(true);
    try {
      let imageLink = "";
      if (imageFile) {
        const formData = new FormData();
        formData.append("file", imageFile);
        const res1 = await axiosClient.post(
          "/cms/media/upload-image",
          formData,
          {}
        );
        if (res1.status === 0) {
          imageLink = res1.data;
        }
      }
      const res = await axiosClient.post("/cms/service/create", {
        name: formik.values.name || "",
        description: formik.values.description || "",
        duration: formik.values.duration,
        price: formik.values.price,
        image: imageLink,
        registerLink: formik.values.registerLink,
        status: "active",
      });
      if (res && res.status === 0) {
        toaster.success("Đã tạo mới thành công");
        history.push("/service/list");
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      image: "",
      duration: 30,
      registerLink: "",
      price: 1000000,
      status: "active",
    },
    onSubmit: (values) => {
      create();
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Bắt buộc nhập tiêu đề"),
    //  description: Yup.string().required("Bắt buộc nhập mô tả"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(4)}
      marginY={majorScale(2)}
      flexDirection="column"
    >
      {false ? (
        <Spinner />
      ) : (
        <Pane
          display="flex"
          marginY={majorScale(2)}
          paddingX={majorScale(4)}
          paddingY={majorScale(3)}
          flexDirection="column"
          background="white"
        >
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            marginBottom={majorScale(2)}
          >
            <HeaderComponent>Tạo mới Sản phẩm</HeaderComponent>
          </Pane>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <RowLabelComponent
              leading="Ảnh đại diện"
              trailing={
                <ImageUpload
                  handleImageSelect={(e) => {
                    setImageFile(e.target.files[0]);
                    const url = URL.createObjectURL(e.target.files[0]);
                    setImage(url);
                  }}
                  defaultDeleteIconSize={50}
                  defaultDeleteIconColor="#222222"
                  deleteIcon={<div color="white">🗑️</div>}
                  imageSrc={image}
                  setImageSrc={setImage}
                  style={{
                    margin: 0,
                    width: 160,
                    height: 100,
                    background: "grey",
                    objectFit: "cover",
                  }}
                />
              }
            />
            <RowLabelComponent
              leading="Tiêu đề"
              trailing={
                <TextInput
                  name="name"
                  placeholder="Nhập tiêu đề gói"
                  type="text"
                  width={600}
                  maxWidth={600}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  isInvalid={formik.errors.name != null}
                />
              }
            />
            <RowLabelComponent
              leading="Mô tả"
              alignItems="flex-start"
              trailing={
                <JoditEditor
                  config={config}
                  onChange={(value) =>
                    formik.setFieldValue("description", value)
                  }
                  // onBlur={handleBlurAreaChange}
                  value={formik.values.description}
                />
              }
            />
            <RowLabelComponent
              leading="Link đăng ký"
              trailing={
                <TextInput
                  name="registerLink"
                  placeholder=""
                  type="text"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.registerLink}
                />
              }
            />
            {/* <RowLabelComponent
              leading="Chu kì (ngày)"
              trailing={
                <TextInput
                  name="duration"
                  placeholder="VD: 300 ngày.."
                  type="number"
                  width={300}
                  maxWidth={300}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.duration}
                />
              }
            />
            <RowLabelComponent
              leading="Giá gói"
              trailing={
                <NumberFormat
                  value={formik.values.price}
                  displayType={"number"}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" đ"
                  width={300}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    formik.setFieldValue("price", value);
                  }}
                  customInput={TextInput}
                />
              }
            /> */}

            <Divider light={true} />
            <Button
              type="submit"
              isLoading={submitting}
              appearance="primary"
              width={150}
              marginTop={majorScale(3)}
              marginRight={majorScale(3)}
            >
              Tạo mới
            </Button>
          </form>
        </Pane>
      )}
    </Pane>
  );
}

export default ServiceCreatePage;
