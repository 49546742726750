import { Heading, majorScale } from "evergreen-ui";

function HeaderComponent(props) {
  return (
    <Heading size={500} margin={majorScale(0)}>
      {props.children.toUpperCase()}
    </Heading>
  );
}

export default HeaderComponent;
