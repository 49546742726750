import { Pagination } from "antd";
import {
  AddIcon,
  Button,
  Heading,
  Image,
  majorScale,
  Pane,
  SearchIcon,
  SearchInput,
  Table,
  Text,
  toaster,
} from "evergreen-ui";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { accessLabel, formatDate } from "../../../helper/business_helper";
import { placeholder } from "../../../helper/image_helper";
import axiosClient from "../../../network/RestClient";
import LoadingFrame from "../../components/LoadingFrame";
import TagComponent from "../../components/TagComponent";

function NewsPage() {
  let { url } = useRouteMatch();
  const history = useHistory();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  // phan trang
  const [page, setPage] = useState(1);
  const [totalResult, setTotalResult] = useState(1);
  const limit = 10;

  const fetchData = async function () {
    setLoading(true);
    try {
      const res = await axiosClient.get("/cms/news/search", {
        params: {
          search: search,
          limit: limit,
          offset: limit * (page - 1),
        },
      });
      if (res && res.status === 0) {
        setList((old) => [...res.data]);
        setTotalResult(res.totalResult);
      } else {
        toaster.danger(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      toaster.danger(err.message);
    }
    setLoading(false);
  };


  useEffect(() => {
    fetchData();
    return () => {};
  }, [page]);

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(5)}
      marginY={majorScale(3)}
      flexDirection="column"
    >
      <Pane
        display="flex"
        justifyContent="flex-start"
        alignItems="start"
        flexDirection="row"
      >
        <SearchInput
          placeholder="Tìm kiếm..."
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          marginRight={majorScale(2)}
          width={300}
        />
        <Button
          appearance="primary"
          iconAfter={SearchIcon}
          onClick={(e) => fetchData()}
        >
          Tìm kiếm
        </Button>

        <Button
          appearance="primary"
          intent="danger"
          iconBefore={<AddIcon />}
          marginLeft="auto"
          onClick={(e) => history.push(url.replace("/list", "/create"))}
        >
          Tạo mới
        </Button>
      </Pane>
      <Table marginTop={majorScale(3)} maxWidth="80vw">
        <Table.Head
          height={35}
          accountForScrollbar={false}
          color="white"
          background="#0984e3"
          borderLeft
          borderRight
        >
          <Table.TextHeaderCell flex={0.3}>#</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={5}>Thông tin</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={1}>Danh mục</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={1}>Quyền xem</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={1}>Cập nhật</Table.TextHeaderCell>
        </Table.Head>
        {loading ? (
          <LoadingFrame />
        ) : (
          <Table.Body height={(80 * limit) + 20} maxHeight={(150 * limit) + 20}>
            {list?.map((item, index) => (
              <Table.Row
                key={item._id}
                isSelectable
                borderLeft
                borderRight
                height={80}
                maxHeight={150}
                paddingY={5}
                onSelect={() => {
                  return history.push(
                    url.replace("/list", "/detail") + "/" + item._id
                  );
                }}
              >
                <Table.TextCell flex={0.3}>
                  {index + 1 + (page - 1) * limit}
                </Table.TextCell>
                <Table.TextCell flex={5}>
                  <Pane
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    maxWidth="55vw"
                  >
                    <Image
                      src={item.image || placeholder}
                      style={{ objectFit: "cover" }}
                      maxWidth={80}
                      marginRight={majorScale(2)}
                    />
                    <Pane display="flex" flexDirection="column">
                      <Heading size={400} marginBottom={2}>
                        {item.title}
                      </Heading>
                      <Text size={300} marginBottom={2}>
                        {item.sapo}
                      </Text>
                      <Pane display="flex" flexDirection="row">
                        {item.keyword.map((e) => (
                          <TagComponent>{e}</TagComponent>
                        ))}
                      </Pane>
                    </Pane>
                  </Pane>
                </Table.TextCell>
                <Table.TextCell flex={1}>
                  <Pane display="flex" flexDirection="column">
                    {item.categoryRef ? item.categoryRef.name : "N/A"}
                  </Pane>
                </Table.TextCell>
                <Table.TextCell flex={1}>
                  {accessLabel(item.accessType)}
                </Table.TextCell>
                <Table.TextCell flex={1}>
                  {formatDate(item.updatedAt)}
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>
      <Pagination
        current={page}
        pageSize={limit}
        total={totalResult}
        showSizeChanger={false}
        alignSelf="end"
        marginTop={majorScale(2)}
        onChange={(value) => {
          setPage(value);
        }}
      />
    </Pane>
  );
}

export default NewsPage;
