import {
  Button,
  Heading,
  majorScale,
  Pane,
  SearchInput,
  Table,
  Text,
  toaster,
} from "evergreen-ui";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import axiosClient from "../../../network/RestClient";
import LoadingFrame from "../../components/LoadingFrame";

function GroupsSelectorPage(props) {
  let { url } = useRouteMatch();
  const history = useHistory();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const fetchData = async function () {
    setLoading(true);
    try {
      const res = await axiosClient.get("/cms/group/search", {
        params: {
          search: search,
        },
      });
      if (res && res.status === 0) {
        setList((old) => [...res.data]);
      } else {
        toaster.danger(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      toaster.danger(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(5)}
      marginY={majorScale(3)}
      flexDirection="column"
    >
      <Pane
        display="flex"
        justifyContent="flex-start"
        alignItems="start"
        flexDirection="row"
      >
        <SearchInput
          placeholder="Tìm kiếm..."
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          marginRight={majorScale(2)}
          width={300}
        />
        <Button appearance="primary" onClick={(e) => fetchData()}>
          Tìm kiếm
        </Button>
      </Pane>
      <Table height="70vh" maxHeight="70vh" marginTop={majorScale(3)}>
        <Table.Head
          height={35}
          color="white"
          background="#0984e3"
          accountForScrollbar={false}
          borderLeft
          borderRight
        >
          <Table.TextHeaderCell flex={0.5}>#</Table.TextHeaderCell>
          <Table.TextHeaderCell flex={5}>Thông tin</Table.TextHeaderCell>
        </Table.Head>
        {loading ? (
          <LoadingFrame />
        ) : (
          <Table.Body>
            {list?.map((item, index) => (
              <Table.Row
                key={item._id}
                isSelectable
                borderLeft
                borderRight
                height={52}
                maxHeight={120}
                paddingY={5}
                onSelect={(e) => {
                  props.onAdd(item)
                }}
              >
                <Table.TextCell flex={0.5}>{index + 1}</Table.TextCell>
                <Table.TextCell flex={5}>
                  <Pane display="flex" flexDirection="column">
                    <Heading size={400}>{item.name}</Heading>
                    <Text size={100}>
                      {item.description || "Chưa có mô tả"}
                    </Text>
                  </Pane>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>
    </Pane>
  );
}

export default GroupsSelectorPage;
