import { Divider } from "@material-ui/core";
import {
  AddIcon,
  Avatar,
  Button,
  CornerDialog,
  Heading,
  IconButton,
  majorScale,
  minorScale,
  NewPersonIcon,
  Pane,
  Paragraph,
  Select,
  SideSheet,
  Spinner,
  Table,
  Text,
  Textarea,
  TextInput,
  toaster,
  TrashIcon,
} from "evergreen-ui";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { removeItemIndex } from "../../../helper/array_helper";
import axiosClient from "../../../network/RestClient";
import HeaderComponent from "../../components/HeaderComponent";
import RowLabelComponent from "../../components/RowLabelComponent";
import UsersSelectorPage from "../user/selector";

function GroupDetailPage() {
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [model, setModel] = useState({});
  const [usersInGroup, setUsersInGroup] = useState([]);

  // dialog xóa
  const [dialogDeleteShow, setDialogDeleteShow] = useState(false);

  //dialog thêm người dùng
  const [dialogUserShow, setDialogUserShow] = useState(false);

  const fetchDetail = async function () {
    setLoading(true);
    try {
      const res = await axiosClient.get("/cms/group/detail", {
        params: {
          id: id,
        },
      });
      if (res && res.status === 0) {
        setModel(res.data);
        setUsersInGroup((old) => res.data.members || []);
      } else {
        toaster.danger(res.message ?? "Không lấy được thông tin");
      }
    } catch (err) {
      toaster.danger(err.message);
    }
    setLoading(false);
  };

  const update = async () => {
    setSubmitting(true);
    try {
      const res = await axiosClient.post("/cms/group/update", {
        id: id,
        name: formik.values.name || "",
        description: formik.values.description || "",
        image: formik.values.image,
        members: usersInGroup.map((e) => e._id),
        status: formik.values.status || "active",
      });
      if (res && res.status === 0) {
        toaster.success("Đã cập nhật thông tin");
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const remove = async () => {
    setSubmitting(true);
    try {
      const res = await axiosClient.get("/cms/group/delete", {
        params: {
          id: id,
        },
      });
      if (res && res.status === 0) {
        toaster.success("Đã xóa");
        history.push("/group/list");
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      name: model?.name || "",
      description: model?.description || "",
      image: model?.image,
      status: model?.status || "active",
    },
    onSubmit: (values) => {
      update();
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      description: Yup.string().required(),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    fetchDetail();
    return () => {};
  }, [id]);

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(4)}
      marginY={majorScale(2)}
      flexDirection="column"
    >
      {loading ? (
        <Spinner />
      ) : (
        <Pane
          display="flex"
          marginY={majorScale(2)}
          paddingX={majorScale(4)}
          paddingY={majorScale(3)}
          flexDirection="column"
          background="white"
        >
          <CornerDialog
            title="Xóa bản ghi?"
            intent="danger"
            isShown={dialogDeleteShow}
            cancelLabel="Hủy"
            confirmLabel="Có xóa"
            onCloseComplete={() => setDialogDeleteShow(false)}
            onConfirm={() => {
              setDialogDeleteShow(false);
              remove();
            }}
          >
            Bạn có chắc chắn muốn xóa bản ghi này?
          </CornerDialog>

          <SideSheet
            isShown={dialogUserShow}
            onCloseComplete={() => setDialogUserShow(false)}
            width="45vw"
            containerProps={{
              display: "flex",
              flex: "1",
              flexDirection: "column",
            }}
          >
            <UsersSelectorPage
              onAdd={(u) => {
                if (!usersInGroup.map((e) => e._id).includes(u._id)) {
                  setUsersInGroup((old) => [...old, u]);
                }
              }}
            />
          </SideSheet>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            marginBottom={majorScale(2)}
          >
            <HeaderComponent>Thông tin Nhóm người dùng</HeaderComponent>
          </Pane>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <RowLabelComponent
              leading="ID"
              trailing={
                <Heading size={400} color="teal">
                  {id}
                </Heading>
              }
            />
            <RowLabelComponent
              leading="Tên nhóm"
              trailing={
                <TextInput
                  name="name"
                  placeholder="Nhập tên nhóm"
                  type="text"
                  width={450}
                  maxWidth={450}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  isInvalid={formik.errors.name != null}
                />
              }
            />
            <RowLabelComponent
              leading="Mô tả"
              trailing={
                <Textarea
                  name="description"
                  placeholder="Nhập mô tả cho nhóm"
                  type="text"
                  width={450}
                  maxWidth={450}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  isInvalid={formik.errors.description != null}
                />
              }
            />
            <RowLabelComponent
              leading="Trạng thái"
              trailing={
                <Select
                  name="status"
                  width={200}
                  maxWidth={200}
                  size="medium"
                  onChange={formik.handleChange}
                  value={formik.values.status}
                >
                  <option value="active">Hoạt động</option>
                  <option value="block">Bị khóa</option>
                </Select>
              }
            />
            <Divider light={true} />
            <RowLabelComponent
              leading="Thành viên nhóm"
              trailing={
                <Button
                  type="button"
                  isLoading={submitting}
                  iconBefore={NewPersonIcon}
                  intent="danger"
                  onClick={(e) => setDialogUserShow(true)}
                >
                  Thêm thành viên
                </Button>
              }
            />
            <Table
              marginTop={majorScale(1)}
              maxWidth="45vw"
              marginBottom={majorScale(1)}
              accountForScrollbar={true}
            >
              <Table.Head height={30} color="black" accountForScrollbar={false}>
                <Table.TextHeaderCell flex={1}>#</Table.TextHeaderCell>
                <Table.TextHeaderCell flex={7}>Người dùng</Table.TextHeaderCell>
                <Table.TextHeaderCell flex={2}>Xóa</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height={240}>
                {usersInGroup?.map((item, index) => (
                  <Table.Row
                    key={item._id}
                    isSelectable
                    borderLeft
                    borderRight
                    height={45}
                    maxHeight={120}
                    paddingY={majorScale(1)}
                  >
                    <Table.TextCell flex={1}>{index + 1}</Table.TextCell>
                    <Table.TextCell flex={7}>
                      <Pane display="flex" flexDirection="column">
                        <Heading size={300}>{item.fullName}</Heading>
                        <Paragraph size={100}>{item.username}</Paragraph>
                      </Pane>
                    </Table.TextCell>
                    <Table.TextCell flex={2}>
                      <IconButton
                        type="button"
                        icon={TrashIcon}
                        intent="danger"
                        size={50}
                        onClick={(e) => {
                          const data = removeItemIndex(usersInGroup, index);
                          setUsersInGroup((old) => [...data]);
                        }}
                      />
                    </Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Button
              type="submit"
              isLoading={submitting}
              appearance="primary"
              width={150}
              marginTop={majorScale(3)}
              marginRight={majorScale(3)}
            >
              Cập nhật
            </Button>

            <Button
              type="button"
              intent="danger"
              isLoading={submitting}
              appearance="primary"
              iconBefore={TrashIcon}
              width={150}
              marginTop={majorScale(3)}
              onClick={(e) => setDialogDeleteShow(true)}
            >
              Xóa
            </Button>
          </form>
        </Pane>
      )}
    </Pane>
  );
}

export default GroupDetailPage;
