import { Divider } from "antd";
import {
  AddIcon,
  Button,
  DocumentIcon,
  Heading,
  IconButton,
  majorScale,
  Pane,
  Select,
  SideSheet,
  Spinner,
  Strong,
  Table,
  Text,
  Textarea,
  TextInput,
  toaster,
  TrashIcon,
} from "evergreen-ui";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { removeItemIndex } from "../../../helper/array_helper";
import axiosClient from "../../../network/RestClient";
import HeaderComponent from "../../components/HeaderComponent";
import RowLabelComponent from "../../components/RowLabelComponent";
import GroupsSelectorPage from "../group/selector";
import NewsSelectorPage from "../news/selector";

function PushNotificationPage() {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [news, setNews] = useState(null);
  const [groups, setGroups] = useState([]);

  const [dialogNewsShow, setDialogNewsShow] = useState(false);
  const [dialogGroupShow, setDialogGroupShow] = useState(false);

  const pushNotification = async (resetForm) => {
    setSubmitting(true);
    try {
      const res = await axiosClient.post("/cms/notify/send", {
        title: formik.values.title,
        message: formik.values.message,
        type: formik.values.type,
        ref: formik.values.type === "news" ? news?._id : null,
        sendTo: formik.values.sendTo,
        groups:
          formik.values.sendTo === "group" ? groups.map((e) => e._id) : [],
      });
      if (res && res.status === 0) {
        toaster.success("Đã gửi tin đi thành công");
        resetForm();
        setNews(null);
        setGroups((old) => []);
      } else {
        toaster.danger(res.message ?? "Có lỗi");
      }
    } catch (err) {
      toaster.success(err.message);
      console.log(err);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      message: "",
      type: "none",
      sendTo: "member",
    },
    onSubmit: (values, { resetForm }) => {
      if (values.sendTo === "group" && groups.length === 0) {
        toaster.danger("Bạn phải chọn ít nhất 1 nhóm để gửi tin");
      } else {
        pushNotification(resetForm);
      }
    },
    validationSchema: Yup.object({
      title: Yup.string().required(),
      message: Yup.string().required(),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignContent="center"
      marginX={majorScale(4)}
      marginY={majorScale(2)}
      flexDirection="column"
    >
      {loading ? (
        <Spinner />
      ) : (
        <Pane
          display="flex"
          marginY={majorScale(2)}
          paddingX={majorScale(4)}
          paddingY={majorScale(3)}
          flexDirection="column"
          background="white"
        >
          <SideSheet
            isShown={dialogNewsShow}
            width="45vw"
            onCloseComplete={() => setDialogNewsShow(false)}
            containerProps={{
              display: "flex",
              flex: "1",
              flexDirection: "column",
            }}
          >
            <NewsSelectorPage
              onAdd={(value) => {
                setNews(value);
                setDialogNewsShow(false);
              }}
            />
          </SideSheet>

          <SideSheet
            isShown={dialogGroupShow}
            onCloseComplete={() => setDialogGroupShow(false)}
            containerProps={{
              display: "flex",
              flex: "1",
              flexDirection: "column",
            }}
          >
            <GroupsSelectorPage
              onAdd={(u) => {
                if (!groups.map((e) => e._id).includes(u._id)) {
                  setGroups((old) => [...old, u]);
                }
              }}
            />
          </SideSheet>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            marginBottom={majorScale(0)}
          >
            <HeaderComponent>Push Notificaiton</HeaderComponent>
          </Pane>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <RowLabelComponent
              leading="Tiêu đề notify"
              trailing={
                <TextInput
                  name="title"
                  placeholder="Nhập tiêu đề"
                  type="text"
                  width={450}
                  maxWidth={450}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  isInvalid={formik.errors.title != null}
                />
              }
            />
            <RowLabelComponent
              leading="Nôi dung tin nhắn"
              trailing={
                <Textarea
                  name="message"
                  placeholder="Nhập nội dung ngắn"
                  type="text"
                  width={450}
                  maxWidth={450}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  isInvalid={formik.errors.message != null}
                />
              }
            />
            <RowLabelComponent
              leading="Loại tin"
              trailing={
                <Select
                  name="type"
                  required
                  width={300}
                  maxWidth={300}
                  size="medium"
                  defaultValue="member"
                  onChange={formik.handleChange}
                  value={formik.values.type}
                >
                  <option key="none" value="none">
                    Tin nhắn không kèm nội dung
                  </option>
                  <option key="recommend" value="recommend">
                    Tin nhắn Khuyến nghị
                  </option>
                  <option key="news" value="news">
                    Tin nhắn bài viết
                  </option>
                </Select>
              }
            />
            {formik.values.type === "news" ? (
              <RowLabelComponent
                leading="Bài viết đính kèm"
                trailing={
                  <Pane display="flex" flexDirection="column">
                    <Button
                      type="button"
                      isLoading={submitting}
                      width={180}
                      intent="danger"
                      iconBefore={DocumentIcon}
                      onClick={(e) => setDialogNewsShow(true)}
                    >
                      Chọn bài viết
                    </Button>
                    <Text size={400} marginTop={majorScale(2)}>
                      {news ? (
                        <Text>
                          {"Đã chọn: "}
                          <Strong> {news?.title}</Strong>
                        </Text>
                      ) : (
                        ""
                      )}
                    </Text>
                  </Pane>
                }
              />
            ) : (
              <Pane />
            )}

            <RowLabelComponent
              leading="Đối tượng nhận tin"
              trailing={
                <Select
                  name="sendTo"
                  required
                  width={300}
                  maxWidth={300}
                  size="medium"
                  defaultValue="member"
                  onChange={formik.handleChange}
                  value={formik.values.sendTo}
                >
                  <option key="all" value="all">
                    Tất cả người cài app
                  </option>
                  <option key="member" value="member">
                    Hạng Member trở lên được nhận tin
                  </option>
                  <option key="premium" value="premium">
                    Từ hạng Gold trở lên được nhận tin
                  </option>
                  <option key="platium" value="platium">
                    Chỉ hạng Platium được nhận tin
                  </option>
                  <option key="group" value="group">
                    Chỉ nhóm cụ thể được nhận tin
                  </option>
                </Select>
              }
            />
            <Divider light={true} />
            {formik.values.sendTo === "group" ? (
              <Pane display="flex" flexDirection="column">
                <Button
                  type="button"
                  isLoading={submitting}
                  iconBefore={AddIcon}
                  maxWidth={140}
                  size="medium"
                  marginBottom={10}
                  onClick={(e) => setDialogGroupShow(true)}
                >
                  Thêm Nhóm
                </Button>
                <Table
                  marginTop={majorScale(1)}
                  maxWidth={600}
                  width={600}
                  marginBottom={majorScale(1)}
                  accountForScrollbar={true}
                >
                  <Table.Head
                    height={30}
                    color="black"
                    accountForScrollbar={false}
                  >
                    <Table.TextHeaderCell flex={1}>#</Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={7}>
                      Nhóm người dùng
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={2}>Xóa</Table.TextHeaderCell>
                  </Table.Head>
                  <Table.Body height={240}>
                    {groups?.map((item, index) => (
                      <Table.Row
                        key={item._id}
                        isSelectable
                        borderLeft
                        borderRight
                        height={45}
                        maxHeight={120}
                        paddingY={majorScale(1)}
                      >
                        <Table.TextCell flex={1}>{index + 1}</Table.TextCell>
                        <Table.TextCell flex={7}>
                          <Heading size={300}>{item.name}</Heading>
                        </Table.TextCell>
                        <Table.TextCell flex={2}>
                          <IconButton
                            type="button"
                            icon={TrashIcon}
                            intent="danger"
                            size={50}
                            onClick={(e) => {
                              const data = removeItemIndex(groups, index);
                              setGroups((old) => [...data]);
                            }}
                          />
                        </Table.TextCell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Pane>
            ) : (
              <Pane />
            )}
            <Divider light={true} />
            <Button
              type="submit"
              isLoading={submitting}
              appearance="primary"
              width={150}
              marginTop={majorScale(3)}
              marginRight={majorScale(3)}
            >
              Push tin đi
            </Button>
          </form>
        </Pane>
      )}
    </Pane>
  );
}

export default PushNotificationPage;
