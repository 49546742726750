import { defaultTheme } from "evergreen-ui";
import { merge } from "lodash";

const config = {
  ...defaultTheme,
  // fontFamilies: {
  //   display: "...",
  //   ui: "...",
  //   mono: "...",
  // },
  components: {
    ...defaultTheme.components,
    Button: {
      ...defaultTheme.components.Button,
      appearances: {
        ...defaultTheme.components.Button.appearances,
        primary: defaultTheme.components.Button.appearances.primary,
      },
    },
  },
};

const mytheme = merge({}, defaultTheme, config);

export default mytheme;
